<template>
    <div>
        <LoadingSpinner :showLoading="!chartInitialized" />
        <div style="position: relative" v-show="chartInitialized">
            <MoreOptionsMenu class="more-options" @click="toggleMenu"/>
            <Menu ref="menu" :model="moreMenuItems" :popup="true" />
            <div id="tradesVisuals" data-qmod-tool="trades" :data-qmod-params="tradesParams" class="trades-qtool" ref="tradesVisuals" />
        </div>

        <template v-if='chartInitialized'>
            <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
            <AttributionFooter authors="quoteMedia" />
        </template>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';

const POLLING_INTERVAL = 5000;

export default {
    name: 'TradesVisuals',

    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
    },
    computed: {
        tradesParams() {
            return JSON.stringify({ lang: 'en', chart: { upColor: '#15b882', downColor: '#df404a', noChangeColor: '#000000' }, showLogo: false, lowHigh: true, symbol: this.selectedSymbol, export: true, autoUpdateEnabled: false, autoUpdateVisible: true});
        },
    },
    watch: {
         $route(newRoute, oldRoute) { //eslint-disable-line
            if (newRoute.fullPath == '/equity/trades') {
                console.log("starting trade visuals polling")
                this.startPolling();
            }
            else {
                console.log("stopping trade visuals polling")
                this.stopPolling();
            }
         }
    },
    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog,
        AttributionFooter,
    },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute('type', 'text/template');
        let txt = document.createTextNode(
            `
            <div class="qmod-ui-tool qmod-trades">
                
                    <div class="qmod-quotehead card" rv-unless="data.entitlement | = 'NA'">
                        <div class="qmod-block-wrapper" rv-addclass="tLang | preFix &quot;qmod-lang-&quot;">
                            <div rv-if="data | isValidSymbol">
                                <h3 class="company-name-container">
                                <span class="flag" rv-if="data.showFlag" rv-addclass="data.key.exchangecountry"></span><span class="qmod-t-text" rv-html="data.equityinfo.longname"></span><span class="qmod-aria-noread" rv-if="data.equityinfo.longname" aria-hidden="true">&nbsp;</span><span class="qmod-aria-noread" rv-if="data.equityinfo.longname" aria-hidden="true">(</span><span rv-html="data.symbolstring | whitespace"></span><span class="qmod-aria-noread" rv-if="data.equityinfo.longname" aria-hidden="true">)</span>
                                <div class="qmod-inline-ul" rv-if="scope.reloadControl.autoUpdate.visible">
                                    <ul class="qmod-buttons qmod-pull-right qmod-head-reload">
                                        <li class="qmod-refresh-control" rv-if="scope.reloadControl.refresh.visible"><button id="trade-refresh-control-button" class="qmod-btn qmod-btn-link" rv-on-click="scope.reloadControl.refresh.click"><i class="fa fa-refresh" rv-class-fa-spin="scope.reloadControl.refresh.spin"></i><span class="qmod-btn-text">{scope.reloadControl.refresh.label}</span></button></li>
                                        
                                    </ul>
                                </div>
                                </h1>
                            </div>
                            <div class="qmod-invalid" rv-unless="data | isEntitled">{tplLang.notentitled| toLang tLang} {tplLang.for| toLang tLang} {data.symbolstring}</div>
                            <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol| toLang tLang}: {data.symbolstring}</div>
                            <div class="pure-g" rv-unless="data.entitlement | = 'NA'">
                                <div class="pure-u-1" rv-class-pure-u-md-1-2="data.qhShowRight">
                                <div class="qmod-head-left" rv-aria-label="'quote data' | i8ln tLang | addWord 'for' | i8ln tLang | addWord data.equityinfo.longname">
                                    <div class="qmod-mkt-hours">
                                        <div class="qmod-mkt-top"><span class="qmod-last" rv-if="data.datatype |= 'index'">{data.pricedata.last | asQHLast tLang}</span><span class="qmod-last" rv-unless="data.datatype |= 'index'">{data.pricedata.last | asQHLast tLang | asCurrency data.key.currency tLang}</span><span class="qmod-change" rv-qmodchange="data.pricedata.change"><span class="qmod-change-icon fa" rv-qmodchangeicon="data.pricedata.change"></span><span class="qmod-tot"><span class="qmod-chg-prefix" rv-if="chg_prefix">+</span><span class="qmod-chg-total">{data.pricedata.change | numeraljs '0.00'}</span></span><span class="qmod-pct"><span class="qmod-brackets">(</span><span class="qmod-chg-prefix" rv-if="chgpct_prefix">+</span><span class="qmod-chg-percent">{data.pricedata.changepercent | numeraljs '0.00' | postFix '%' tLang}</span><span class="qmod-brackets">)</span></span></span><span class="qmod-reghalt" rv-if="data.status"><a class="qmod-tooltip" rv-if="data.status.regsho" rv-title="data.status.regsho | asRegsho" rv-on-mouseenter="data.qmtooltip">{data.status.regsho}</a><a class="qmod-tooltip" rv-if="data.status.halt" rv-title="data.status.halt.code | asHaltCode" rv-on-mouseenter="data.qmtooltip">{data.status.halt.code}</a></span></div>
                                        <div class="qmod-mkt-mid"><span class="qmod-bid" rv-if="data.pricedata.bid"><span class="qmod-lbl">{tplLang.bid | toLang tLang}:&nbsp;</span><span class="qmod-dp">{data.pricedata.bid | asQHLast tLang}</span><span class="qmod aria-hidden">&nbsp;x&nbsp;</span><span class="qmod-dp">{data.pricedata.bidsize}</span></span><span class="qmod-ask" rv-if="data.pricedata.ask"><span class="qmod-lbl">{tplLang.ask | toLang tLang}:&nbsp;</span><span class="qmod-dp">{data.pricedata.ask | asQHLast tLang}</span><span class="qmod aria-hidden">&nbsp;x&nbsp;</span><span class="qmod-dp">{data.pricedata.asksize}</span></span></div>
                                        <div class="qmod-mkt-btm"><span class="qmod-datetime" rv-if="data.pricedata.lasttradedatetime"><span rv-hide="data.entitlement | or_comma_list 'PD,EOD,PW'"><span class="qmod-datetime-date" rv-html="data.pricedata.lasttradedatetime | momentjs 'true' 'LLL' tLang"></span><span class="qmod-datetimezone">{data.tz | asTimezone tLang}</span></span><span rv-show="data.entitlement | or_comma_list 'PD,EOD,PW'"><span rv-html="data.pricedata.lasttradedatetime | momentjs 'true' 'LL' tLang"></span></span></span><span class="qmod-datetime" rv-unless="data.pricedata.lasttradedatetime"><span class="qmod-datetime-date" rv-show="data.datetime" rv-html="data.datetime | momentjs 'true' 'LL' tLang"></span></span><span class="qmod-volume" rv-unless="data.datatype | = 'equity'"><span class="qmod-sm-hide" rv-if="data.pricedata.sharevolume"> <span class="qmod-lbl">Volume:&nbsp;</span><span>{data.pricedata.sharevolume | numeraljs '0,0' 'N/A' tLang}</span></span></span><span class="qmod-volume" rv-if="data.datatype | = 'equity'"><span class="qmod-sm-hide" rv-if="data.pricedata.sharevolume | exists"> <span class="qmod-lbl">Volume:&nbsp;</span><span>{data.pricedata.sharevolume | numeraljs '0,0' '0' tLang}</span></span></span></div>
                                    </div>
                                    <div class="qmod-after-hours" rv-unless="data.afterhours.last|='N/A'" rv-show="data.datatype | or 'etf' 'equity'"><span class="qmod-afh"> <span class="qmod-lbl">{tplLang.afterhours| toLang tLang}&nbsp;</span><span class="qmod-last" rv-html="data.afterhours.last | asQHLast tLang | asCurrency data.key.currency tLang"></span><span class="qmod-tot"><span class="qmod-chg-prefix" rv-if="chg_prefix_afterHours">+</span><span class="qmod-chg-total">{data.afterhours.change | numeraljs '0.00'}</span></span><span class="qmod-pct"><span class="qmod-brackets">(</span><span class="qmod-chg-prefix" rv-if="chgpct_prefix_afterHours">+</span><span class="qmod-chg-percent">{data.afterhours.changepercent | numeraljs '0.00' | postFix '%' tLang}</span><span class="qmod-brackets">)</span></span></span><span class="qmod-ah-bottom"><span class="qmod-datetime"><span class="qmod-datetime-date" rv-html="data.afterhours.lasttradedatetime | momentjs 'true' 'LLL' tLang"></span><span class="qmod-datetimezone">{data.tz | asTimezone tLang}</span></span><span class="qmod-volume" rv-if="data.afterhours.sharevolume"><span class="qmod-sm-hide">Volume: {data.afterhours.sharevolume | numeraljs '0,0' 'N/A' tLang}</span></span></span></div>
                                    <div class="qmod-entline"><span class="qmod-ent-item" rv-if="data.key.currency"><span rv-html="data.key.currency"></span></span><span class="qmod-ent-item" rv-if="data.key.exLgName"><span rv-html="data.key.exLgName"></span></span><span class="qmod-ent-item" rv-if="data.entitlement"><span rv-html="data.entitlement | asEnt tLang"></span></span><span class="qmod-ent-item" rv-if="data.key.iscurrentlyopen" rv-show="data.key.iscurrentlyopen | = 'false'"><span rv-html="tplLang.marketclosed | toLang tLang"></span></span><span rv-unless="data.key.iscurrentlyopen"><span class="qmod-ent-item-optional-last" rv-if="data.key.isopen" rv-show="data.key.isopen | = 'false'"><span rv-html="tplLang.marketclosed | toLang tLang"></span></span></span></div>
                                </div>
                                </div>
                                <div class="pure-u-1 pure-u-md-1-2" rv-if="data.qhShowRight">
                                <div class="qmod-head-right" rv-if="data.lowHigh">
                                    <div class="qmod-daylow" rv-if="data.pricedata.low">
                                        <span class="qmod-day-low">{tplLang.low| toLang tLang}: {data.pricedata.low | asQHLast tLang}</span><span class="qmod-day-high">{tplLang.high| toLang tLang}: {data.pricedata.high | asQHLast tLang}</span>
                                        <div class="qmod-daylowbar"><span class="qmod-hl-arrow" rv-style-left="data.dayhighLow.arrow"><i class="fa fa-caret-down" aria-hidden="true"></i><span class="qmod-hl-label" rv-class-qmod-hl-left="data.dayhighLow.arrowClass | call"> {tplLang.prevclose| toLang tLang}: {data.pricedata.prevclose | asQHLast tLang}</span></span><span class="qmod-barpct" rv-style-width="data.dayhighLow.barPct">{tplLang.last| toLang tLang}: {data.pricedata.last | asQHLast tLang}</span></div>
                                    </div>
                                    <div class="qmod-daylow" rv-if="data.fundamental.week52high">
                                        <span class="qmod-day-low">{tplLang.yearlow| toLang tLang}: {data.fundamental.week52low.content | asQHLast tLang}</span><span class="qmod-day-high">{tplLang.yearhigh| toLang tLang}:  {data.fundamental.week52high.content | asQHLast tLang}</span>
                                        <div class="qmod-daylowbar"><span class="qmod-hl-arrow" rv-style-left="data.yearhighLow.arrow"><i class="fa fa-caret-down" aria-hidden="true"></i><span class="qmod-hl-label" rv-class-qmod-hl-left="data.yearhighLow.arrowClass"> {tplLang.prevclose| toLang tLang}: {data.pricedata.prevclose | asQHLast tLang}</span></span><span class="qmod-barpct" rv-style-width="data.yearhighLow.barPct">{tplLang.last| toLang tLang}: {data.pricedata.last | asQHLast tLang}</span></div>
                                    </div>
                                </div>
                                <div class="qmod-head-right" rv-if="data.tradeURL">
                                    <div class="qmod-tradecont">
                                        <a rv-href="data.tradeURLbuy">
                                            <div class="qmod-btn qmod-green">{tplLang.buyl| toLang tLang}</div>
                                        </a>
                                        <a rv-href="data.tradeURLsell">
                                            <div class="qmod-btn qmod-red">{tplLang.selll| toLang tLang}</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="qmod-head-right" rv-if="data.showLogo" rv-show="data.logo">
                                    <div class="qmod-cplogo"><img rv-src="data.logo" rv-alt="data.equityinfo.longname"/></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-quotehead" rv-unless="data | isEntitled">
                        <div class="qmod-block-wrapper">
                            <h1 class="qmod-heading qmod-hbg"><span class="flag" rv-if="data.showFlag" rv-addclass="data.key.exchangecountry"></span><span class="qmod-t-text">{data.equityinfo.longname}&nbsp;</span>(<span rv-html="data.symbolstring | whitespace"></span>)</h1>
                        </div>
                    </div>
                    <div class="card">
                        <h3 class="trades-header" rv-if="corpEvents.tradesummary">{tplLang.tradesummary | toLang tLang}</h2>
                        <div class="qmod-block-wrapper" rv-if="corpEvents.tradesummary">
                            <div class="qmod-block-wrapper-body">
                                <div class="pure-g">
                                    <div class="pure-u-1-1">
                                    <div class="qmod-panel qmod-panel-summary">
                                        <div class="qmod-panel-body">
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-summary-label"><span>{tplLang.trades | toLang tLang}:</span></div>
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-textl qmod-tsummary-pad"><span>{corpEvents.tradesummary.tradevolume | numeraljs '0,0.00a'}</span></div>
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-summary-label"><span>{tplLang.value | toLang tLang}:</span></div>
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-textl qmod-tsummary-pad"><span>{corpEvents.tradesummary.totalvalue | numeraljs '0,0.00a'}</span></div>
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-summary-label"><span>{tplLang.vwap | toLang tLang}:</span></div>
                                            <div class="pure-u-1-2 pure-u-sm-1-4 pure-u-md-1-8 qmod-textl"><span>{corpEvents.tradesummary.vwap | asQHLast }</span></div>
                                            <div class="pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-4 qmod-summary-datetime"><span class="qmod-summary-datetime">{corpEvents.trade.0.datetime | momentjs 'true' 'L' tLang} {corpEvents.trade.0.datetime | momentjs 'true' 'LT' tLang} {corpEvents.trade.0.timezone | asTimezone tLang}</span></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <h3 class="trades-header" rv-if="corpEvents.trade">{tplLang.timeandsales | toLang tLang}</h2>
                        <div class="qmod-block-wrapper" rv-if="corpEvents.trade">
                            <div class="qmod-block-wrapper-body">
                                <div class="qmod-chart-history">
                                    <!--img(rv-src="qmurl.url")-->
                                </div>
                            </div>
                            <div class="qmod-textr" rv-if="scope.export">
                                <div class="qmod-histrad-setting qmod-historic-con">
                                    <div class="qmod-export"></div>
                                </div>
                            </div>
                            <div class="qmod-block-wrapper-body">
                                <table class="qmod-table qmod-trades-table" width="100%">
                                    <thead>
                                    <tr>
                                        <th class="qmod-control"></th>
                                        <th class="qmod-textl qmod-trade-datetime">{tplLang.datetime | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-indicator">{tplLang.type | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-price">{tplLang.price | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-shares">{tplLang.shares | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-exchange">{tplLang.exchangemkt | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-buyer" rv-if="corpEvents.trade.0.buyer">{tplLang.buyer | toLang tLang}</th>
                                        <th class="qmod-textr qmod-trade-buyer" rv-if="corpEvents.trade.0.buyer">Buyer Name</th>
                                        <th class="qmod-textr qmod-trade-seller" rv-if="corpEvents.trade.0.seller">{tplLang.seller | toLang tLang}
                                        <th class="qmod-textr qmod-trade-seller" rv-if="corpEvents.trade.0.seller">Seller Name</th>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="qmod-trades-table-row" rv-each-tradee="corpEvents.trade" rv-class-qmod-trade-cancelled="tradee.tradestatus |= 'isCancelled'" rv-style-color="tradee.rowColor" rv-addclass="tradee.tickDirection | preFix 'qmod-tick-' | postFix ' qmod-trade-row' | postFix index">
                                        <td class="qmod-control"></td>
                                        <td class="qmod-textl qmod-trade-datetime">{tradee.datetime | momentjs 'true' 'L' tLang} {tradee.datetime | momentjs 'true' 'LT' tLang} {tradee.timezone | asTimezone tLang}</td>
                                        <td class="qmod-textr qmod-trade-indicator"><span rv-unless="tradee.tradeinddesc">{tradee.indicator}</span><a class="qmod-popup-link" rv-title="tradee.tradeinddesc" rv-on-mouseenter="data.qmtooltip" rv-if="tradee.tradeinddesc">{tradee.indicator}</a></td>
                                        <td class="qmod-textr qmod-trade-price">{tradee.price | asQHLast tLang}</td>
                                        <td class="qmod-textr qmod-trade-shares" rv-if="tradee.volume">{tradee.volume}</td>
                                        <td class="qmod-textr qmod-trade-shares" rv-unless="tradee.volume">-</td>
                                        <td class="qmod-textr qmod-trade-exchange">{tradee.trade-exchangeshortname}</td>
                                        <td class="qmod-textr qmod-trade-buyer" rv-if="tradee.buyer"><a class="qmod-popup-link qmod-tooltip" rv-title="tradee.buyer.name" rv-on-mouseenter="data.qmtooltip">{tradee.buyer.id}</a></td>
                                        <td class="qmod-textr qmod-trade-buyer" rv-if="tradee.buyer"><a class="qmod-popup-link qmod-tooltip">{tradee.buyer.name}</a></td>
                                        <td class="qmod-textr qmod-trade-seller" rv-if="tradee.seller"><a class="qmod-popup-link qmod-tooltip" rv-title="tradee.seller.name" rv-on-mouseenter="data.qmtooltip">{tradee.seller.id}</a></td>
                                        <td class="qmod-textr qmod-trade-buyer" rv-if="tradee.seller"><a class="qmod-popup-link qmod-tooltip">{tradee.seller.name}</a></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                   
                    <h2 class="qmod-heading qmod-hbg" rv-unless="corpEvents.trade">{tplLang.trades | toLang tLang}</h2>
                    <div class="qmod-block-wrapper" rv-unless="corpEvents.trade">
                        <div class="qmod-invalid" rv-if="data | isValidSymbol">No Data Exists for {data.symbolstring}</div>
                        <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol | toLang tLang}: {data.symbolstring}</div>
                    </div>
                
            </div>` 
            
            // `<div class="qmod-ui-tool qmod-trades">
            //     <div class="card">
            //         <div class="qmod-quotehead" rv-unless="data.entitlement | = 'NA'">
            //             <div class="qmod-block-wrapper" rv-addclass="tLang | preFix &quot;qmod-lang-&quot;">
            //                 <div rv-if="data | isValidSymbol">
            //                     <h3 class="company-name-container">
            //                         <span class="flag" rv-if="data.showFlag" rv-addclass="data.key.exchangecountry"></span><span class="qmod-t-text" rv-html="data.equityinfo.longname"></span><span class="qmod-aria-noread" aria-hidden="true">&nbsp;</span><span class="qmod-aria-noread" aria-hidden="true">(</span><span rv-html="data.symbolstring | whitespace"></span><span class="qmod-aria-noread" aria-hidden="true">)</span>
            //                         <div class="qmod-inline-ul" rv-if="scope.reloadControl.autoUpdate.visible">
            //                             <ul class="qmod-buttons qmod-pull-right qmod-head-reload">
            //                                 <li class="qmod-refresh-control" rv-if="scope.reloadControl.refresh.visible"><button id="trade-refresh-control-button" class="qmod-btn qmod-btn-link" rv-on-click="scope.reloadControl.refresh.click"><i class="fa fa-refresh" rv-class-fa-spin="scope.reloadControl.refresh.spin"></i><span class="qmod-btn-text">{scope.reloadControl.refresh.label}</span></button></li>
            //                                 <li class="qmod-autoupdate-control" rv-if="scope.reloadControl.autoUpdate.visible"><button class="qmod-btn qmod-btn-link" rv-on-click="scope.reloadControl.autoUpdate.click"><i class="fa" rv-ifclass="scope.reloadControl.autoUpdate.enabled" data-qmod-true-class="fa-toggle-on qmod-ch-up" data-qmod-false-class="fa-toggle-off qmod-ch-down"></i><span class="qmod-btn-text">{scope.reloadControl.autoUpdate.label}</span></button></li>
            //                             </ul>
            //                         </div>
            //                     </h3>
            //                 </div>
            //                 <div class="qmod-invalid" rv-unless="data | isEntitled">{tplLang.notentitled| toLang tLang} {tplLang.for| toLang tLang} {data.symbolstring}</div>
            //                 <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol| toLang tLang}: {data.symbolstring}</div>
            //                 <div class="pure-g" rv-unless="data.entitlement | = 'NA'">
            //                     <div class="pure-u-1" rv-class-pure-u-md-1-2="data.qhShowRight">
            //                         <div>
            //                             <div class="qmod-mkt-hours">
            //                                 <div class="qmod-mkt-top"><span class="qmod-last" rv-if="data.datatype |= 'index'">{data.pricedata.last | asQHLast tLang}</span><span class="qmod-last" rv-unless="data.datatype |= 'index'">{data.pricedata.last | asQHLast tLang | asCurrency data.key.currency tLang} {data.key.currency}</span><span class="qmod-change" rv-qmodchange="data.pricedata.change"><span class="qmod-change-icon fa" rv-qmodchangeicon="data.pricedata.change"></span><span class="qmod-tot"><span class="qmod-chg-prefix" rv-if="chg_prefix">+</span><span class="qmod-chg-total">{data.pricedata.change | numeraljs '0.00'}</span></span><span class="qmod-pct"><span class="qmod-brackets">(</span><span class="qmod-chg-prefix" rv-if="chgpct_prefix">+</span><span class="qmod-chg-percent">{data.pricedata.changepercent | numeraljs '0.00' | postFix '%' tLang}</span><span class="qmod-brackets">)</span></span></span><span class="qmod-reghalt" rv-if="data.status"><a class="qmod-tooltip" rv-if="data.status.regsho" rv-title="data.status.regsho | asRegsho" rv-on-mouseenter="data.qmtooltip">{data.status.regsho}</a><a class="qmod-tooltip" rv-if="data.status.halt" rv-title="data.status.halt.code | asHaltCode" rv-on-mouseenter="data.qmtooltip">{data.status.halt.code}</a></span></div>
            //                                 <table class="qmod-mkt-mid">
            //                                     <tr>
            //                                         <td><span aria-hidden="true">{tplLang.bid| toLang tLang}:&nbsp;</span></td>
            //                                         <td><span rv-aria-label="tplLang.bid | toLang tLang | addWord data.pricedata.bid">{data.pricedata.bid | asQHLast tLang}</span><span class="qmod aria-hidden" aria-hidden="true">&nbsp;x&nbsp;</span><span class="qmod-dp" rv-aria-label="tplLang.bidsize | toLang tLang | addWord data.pricedata.bidsize">{data.pricedata.bidsize}</span><span class="qmod-ask" rv-if="data.pricedata.ask"></span></td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td><span aria-hidden="true">{tplLang.ask| toLang tLang}:&nbsp;</span></td>
            //                                         <td><span rv-aria-label="tplLang.ask | toLang tLang | addWord data.pricedata.ask">{data.pricedata.ask | asQHLast tLang}</span><span class="qmod aria-hidden" aria-hidden="true">&nbsp;x&nbsp;</span><span class="qmod-dp" rv-aria-label="tplLang.asksize | toLang tLang | addWord data.pricedata.asksize">{data.pricedata.asksize}</span></td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td><span rv-if="data.pricedata.sharevolume | exists">Volume: </span></td>
            //                                         <td><span rv-if="data.pricedata.sharevolume | exists">{data.pricedata.sharevolume | numeraljs '0,0' '0' tLang}</span></td>
            //                                     </tr>
            //                                     <tr rv-unless="data.afterhours.last|='N/A'">
            //                                         <td><span>{tplLang.afterhours| toLang tLang}&nbsp;</span></td>
            //                                         <td>
            //                                             <span class="qmod-last-price" rv-html="data.afterhours.last | asQHLast tLang | asCurrency data.key.currency tLang"></span>
            //                                             <span class="qmod-chg-prefix" rv-if="chg_prefix_afterHours">+</span>
            //                                             <span class="qmod-chg-total">{data.afterhours.change | numeraljs '0.00'}</span>
            //                                             <span class="qmod-brackets">(</span>
            //                                             <span class="qmod-chg-prefix" rv-if="chgpct_prefix_afterHours">+</span>
            //                                             <span rv-if="data.pricedata.sharevolume | exists">{data.pricedata.sharevolume | numeraljs '0,0' '0' tLang}</span>
            //                                             <span class="qmod-brackets">)</span>
            //                                         </td>
            //                                     </tr>
            //                                 </table>
            //                                 <div class="qmod-mkt-btm"><span class="qmod-datetime" rv-if="data.pricedata.lasttradedatetime"><span rv-hide="data.entitlement | or_comma_list 'PD,EOD,PW'"><span class="qmod-datetime-date" rv-html="data.pricedata.lasttradedatetime | momentjs 'true' 'LLL' tLang"></span><span class="qmod-datetimezone">{data.tz | asTimezone tLang}</span></span><span rv-show="data.entitlement | or_comma_list 'PD,EOD,PW'"><span rv-html="data.pricedata.lasttradedatetime | momentjs 'true' 'LL' tLang"></span></span></span><span class="qmod-datetime" rv-unless="data.pricedata.lasttradedatetime"><span class="qmod-datetime-date" rv-show="data.datetime" rv-html="data.datetime | momentjs 'true' 'LL' tLang"></span></span><span class="qmod-volume" rv-unless="data.datatype | = 'equity'"><span class="qmod-sm-hide" rv-if="data.pricedata.sharevolume">Volume: {data.pricedata.sharevolume | numeraljs '0,0' 'N/A' tLang}</span></span><span class="qmod-volume" rv-if="data.datatype | = 'equity'"></div>
            //                             </div>
            //                             <div class="qmod-entline"><span class="qmod-ent-item" rv-if="data.key.currency"><span rv-html="data.key.currency"></span></span><span class="qmod-ent-item" rv-if="data.key.exLgName"><span rv-html="data.key.exLgName"></span></span><span class="qmod-ent-item" rv-if="data.entitlement"><span rv-html="data.entitlement | asEnt tLang"></span></span><span class="qmod-ent-item" rv-if="data.key.iscurrentlyopen" rv-show="data.key.iscurrentlyopen | = 'false'"><span rv-html="tplLang.marketclosed | toLang tLang"></span></span><span rv-unless="data.key.iscurrentlyopen"><span class="qmod-ent-item-optional-last" rv-if="data.key.isopen" rv-show="data.key.isopen | = 'false'"><span rv-html="tplLang.marketclosed | toLang tLang"></span></span></span></div>
            //                         </div>
            //                     </div>
            //                     <div class="pure-u-1 pure-u-md-1-2" rv-if="data.qhShowRight">
            //                         <div class="qmod-head-right" rv-if="data.lowHigh">
            //                             <div class="qmod-daylow" rv-if="data.pricedata.low">
            //                                 <span class="qmod-day-low">{tplLang.low| toLang tLang}: {data.pricedata.low | asQHLast tLang}</span><span class="qmod-day-high">{tplLang.high| toLang tLang}: {data.pricedata.high | asQHLast tLang}</span>
            //                                 <div class="qmod-daylowbar"><span class="qmod-hl-arrow" rv-style-left="data.dayhighLow.arrow"><i class="fa fa-caret-down" aria-hidden="true"></i><span class="qmod-hl-label" rv-class-qmod-hl-left="data.dayhighLow.arrowClass | call"> {tplLang.prevclose| toLang tLang}: {data.pricedata.prevclose | asQHLast tLang}</span></span><span class="qmod-barpct" rv-style-width="data.dayhighLow.barPct">{tplLang.last| toLang tLang}: {data.pricedata.last | asQHLast tLang}</span></div>
            //                             </div>
            //                             <div class="qmod-daylow" rv-if="data.fundamental.week52high">
            //                                 <span class="qmod-day-low">{tplLang.yearlow| toLang tLang}: {data.fundamental.week52low.content | asQHLast tLang}</span><span class="qmod-day-high">{tplLang.yearhigh| toLang tLang}:  {data.fundamental.week52high.content | asQHLast tLang}</span>
            //                                 <div class="qmod-daylowbar"><span class="qmod-hl-arrow" rv-style-left="data.yearhighLow.arrow"><i class="fa fa-caret-down" aria-hidden="true"></i><span class="qmod-hl-label" rv-class-qmod-hl-left="data.yearhighLow.arrowClass"> {tplLang.prevclose| toLang tLang}: {data.pricedata.prevclose | asQHLast tLang}</span></span><span class="qmod-barpct" rv-style-width="data.yearhighLow.barPct">{tplLang.last| toLang tLang}: {data.pricedata.last | asQHLast tLang}</span></div>
            //                             </div>
            //                         </div>
            //                         <div class="qmod-head-right" rv-if="data.tradeURL">
            //                             <div class="qmod-tradecont">
            //                                 <a rv-href="data.tradeURLbuy">
            //                                     <div class="qmod-btn qmod-green">{tplLang.buyl| toLang tLang}</div>
            //                                 </a>
            //                                 <a rv-href="data.tradeURLsell">
            //                                     <div class="qmod-btn qmod-red">{tplLang.selll| toLang tLang}</div>
            //                                 </a>
            //                             </div>
            //                         </div>
            //                         <div class="qmod-head-right" rv-if="data.showLogo">
            //                             <div class="qmod-cplogo"><img rv-src="'//app.quotemedia.com/quotetools/getLogo?&amp;webmasterId=501&amp;symbol=' | postFix data.symbolstring"/></div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //         <div class="qmod-quotehead" rv-unless="data | isEntitled">
            //             <div class="qmod-block-wrapper">
            //                 <h1 class="qmod-heading qmod-hbg"><span class="flag" rv-if="data.showFlag" rv-addclass="data.key.exchangecountry"></span><span class="qmod-t-text">{data.equityinfo.longname}&nbsp;</span>(<span rv-html="data.symbolstring | whitespace"></span>)</h1>
            //             </div>
            //         </div>
            //     </div>
            //     <div class="card">
            //         <div class="trades-summary-top-container">
            //             <h3 class="trades-header">Trades Summary</h3>
            //             <!--<button id="refresh-button" class="p-button p-component follow-button green-button__secondary" type="button"><span><i class="fa fa-refresh"></i> Refresh</span></button>-->
            //         </div>
            //         <div class="qmod-block-wrapper" rv-if="corpEvents.tradesummary">
            //             <div class="qmod-block-wrapper-body">
            //                 <table class="qmod-mkt-tradesummary">
            //                     <tr>
            //                         <td><span>{tplLang.trades | toLang tLang}:</span> <span>{corpEvents.tradesummary.tradevolume | numeraljs '0,0.00a'}</span></td>
            //                         <td><span>{tplLang.value | toLang tLang}:</span> <span>{corpEvents.tradesummary.totalvalue | numeraljs '0,0.00a'}</span></td>
            //                         <td><span>{tplLang.vwap | toLang tLang}:</span> <span>{corpEvents.tradesummary.vwap | asQHLast }</span></td>
            //                         <td><span class="qmod-summary-datetime">{corpEvents.trade.0.datetime | momentjs 'true' 'L' tLang} {corpEvents.trade.0.datetime | momentjs 'true' 'LT' tLang} {corpEvents.trade.0.timezone | asTimezone tLang}</span></td>
            //                     </tr>
            //                 </table>            
            //             </div>
            //         </div>
            //     </div>
                
            //     <div style="display:none;" class="card" rv-if="corpEvents.trade">
            //         <h3 class="trades-header" rv-if="corpEvents.trade">Trades Chart</h3>
            //         <div class="qmod-block-wrapper" rv-if="corpEvents.trade">
            //             <div class="qmod-block-wrapper-body">
            //                 <div class="qmod-chart-history">
            //                     <!--img(rv-src="qmurl.url")-->
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
                
            //     <div class="card">
            //         <div class="trades-details-top-container">
            //             <h3 class="trades-header">Trades Detail</h3>
            //             <div class="qmod-textr" rv-if="scope.export">
            //                 <div class="qmod-histrad-setting qmod-historic-con">
            //                     <div class="qmod-export"></div>
            //                 </div>
            //             </div>
            //         </div>
            //         <div class="qmod-block-wrapper" rv-if="corpEvents.trade">
            //             <div class="qmod-block-wrapper-body">
            //                 <table class="qmod-table qmod-trades-table" width="100%">
            //                     <thead>
            //                         <tr>
            //                             <th class="qmod-control"></th>
            //                             <th class="qmod-textl qmod-trade-datetime">Date/Time</th>
            //                             <th class="qmod-textr qmod-trade-indicator">Type</th>
            //                             <th class="qmod-textr qmod-trade-price">Price</th>
            //                             <th class="qmod-textr qmod-trade-shares">Shares</th>
            //                             <th class="qmod-textr qmod-trade-exchange">Exch/Mkt</th>
            //                             <th class="qmod-textr qmod-trade-buyer" rv-if="corpEvents.trade.0.buyer">Buyer</th>
            //                             <th class="qmod-textr qmod-trade-seller" rv-if="corpEvents.trade.0.seller">Seller</th>
            //                         </tr>
            //                     </thead>
            //                     <tbody>
            //                         <tr class="qmod-trades-table-row" rv-each-tradee="corpEvents.trade" rv-class-qmod-trade-cancelled="tradee.tradestatus |= 'isCancelled'" rv-style-color="tradee.rowColor" rv-addclass="tradee.tickDirection | preFix 'qmod-tick-' | postFix ' qmod-trade-row' | postFix index">
            //                             <td class="qmod-control"></td>
            //                             <td class="qmod-textl qmod-trade-datetime">{tradee.datetime | momentjs 'true' 'L' tLang} {tradee.datetime | momentjs 'true' 'LT' tLang} {tradee.timezone | asTimezone tLang}</td>
            //                             <td class="qmod-textr qmod-trade-indicator">
            //                                 <span rv-unless="tradee.tradeinddesc">{tradee.indicator}</span>
            //                                 <a class="qmod-popup-link" rv-title="tradee.tradeinddesc" rv-on-mouseenter="data.qmtooltip" rv-if="tradee.tradeinddesc">{tradee.indicator}</a>
            //                             </td>
            //                             <td class="qmod-textr qmod-trade-price">{tradee.price | asQHLast tLang}</td>
            //                             <td class="qmod-textr qmod-trade-shares" rv-if="tradee.volume">{tradee.volume}</td>
            //                             <td class="qmod-textr qmod-trade-shares" rv-unless="tradee.volume">-</td>
            //                             <td class="qmod-textr qmod-trade-exchange">{tradee.trade-exchangeshortname}</td>
            //                             <td class="qmod-textr qmod-trade-buyer" rv-if="tradee.buyer">
            //                                 <a class="qmod-popup-link qmod-tooltip" rv-title="tradee.buyer.name" rv-on-mouseenter="data.qmtooltip">{tradee.buyer.id}</a>
            //                             </td>
            //                             <td class="qmod-textr qmod-trade-seller" rv-if="tradee.seller">
            //                                 <a class="qmod-popup-link qmod-tooltip" rv-title="tradee.seller.name" rv-on-mouseenter="data.qmtooltip">{tradee.seller.id}</a>
            //                             </td>
            //                         </tr>
            //                     </tbody>
            //                 </table>
            //             </div>
            //         </div>
            //         <div class="qmod-block-wrapper" rv-unless="corpEvents.trade">
            //             <div class="qmod-invalid" rv-if="data | isValidSymbol">No Data Exists for {data.symbolstring}</div>
            //             <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol | toLang tLang}:{data.symbolstring}</div>
            //         </div>
            //     </div>
            // </div>`
        );
        tmplEle.appendChild(txt);
        this.$refs.tradesVisuals.appendChild(tmplEle);

        this.$refs.tradesVisuals.addEventListener(
            'qmod-after-template-bind',
            () => {
                console.debug('trades visuals template bound**************');
                this.chartInitialized = true;

                this.$nextTick(() => {
                    window.dispatchEvent(new Event('resize'));
                    const refreshButton = document.getElementById("refresh-button");
                    if(refreshButton){
                        refreshButton.addEventListener("click", this.refresh);
                    }
                });
            },
            false
        ); //should ensure it is fired only once  {once:true}

        this.justMounted = true;
        window.qMod.loadTool('tradesVisuals');
        console.log("trades visual mounted");
    },
    unmounted() {
        console.log("trades visual unmounted");
        this.stopPolling();
    },
    activated() {
        console.debug(this.$options.name + ' activated');
        
    },
    deactivated() {
        console.debug(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.displaySnapshot = false;

       this.stopPolling();
    },

    data() {
        return {
            chartInitialized: false,
            justMounted: false,
            displaySnapshot: false,
            snapshotTarget: null,
            refetcher: null,
            moreMenuItems: [
                {
                    label: 'Take Snapshot',

                    icon: 'pi pi-camera',
                    command: () => {
                        this.openSnapshotDialog();
                    },
                },
            ],
        };
    },

    methods: {
        stopPolling() {
            
             if (this.refetcher) {
                    
                clearInterval(this.refetcher);
            }
        },
        startPolling() {
           this.stopPolling();

            this.refetcher = setInterval(() => {
                let element = document.getElementById('trade-refresh-control-button');
                if( element ) {
                    console.log("clicking refresh controle")
                    element.click();
                }
                else {
                    console.log("No refresh control found")
                }
            }, POLLING_INTERVAL);
        },


        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },

        openSnapshotDialog() {
            this.snapshotTarget = this.$refs.tradesVisuals.getElementsByClassName('qmod-chart-history')[0];
            this.displaySnapshot = true;
        },

        refresh(){
            console.log("TradesVisuals refresh");
            window.qMod.loadTool('tradesVisuals');
        },
    },
};
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 16px;
    top: 16px;
}

::v-deep(.card) {
    margin-bottom: 16px;
}

::v-deep(h2),
::v-deep(h3),
::v-deep(div),
::v-deep(text),
::v-deep(tspan),
::v-deep(.qmod-ui-tool) {
    font-family: 'Trebuchet MS', Verdana !important;
}

::v-deep(.qmod-control) {
    display: none;
}

::v-deep(.trades-header) {
    font-size: 24px;
    font-weight: 600;
    color: #32364e;
    padding: 0px;
    margin-bottom: 16px;
}

::v-deep(.highcharts-container) {
    height: 300px !important;
    width: 100% !important;
}

::v-deep(.highcharts-root) {
    height: 300px !important;
    width: 100% !important;
}

::v-deep(.highcharts-background) {
    height: 300px;
    width: 100%;
}

::v-deep(.highcharts-plot-background) {
    height: 300px;
    width: 100% !important;
}

::v-deep(.qmod-trade-datetime) {
    width: 25%;
}

::v-deep(.qmod-block-wrapper) {
    margin-bottom: -15px !important;
}

::v-deep(.qmod-block-wrapper-body) {
    padding: 0px !important;
}


::v-deep(.qmod-trades .company-name-container div.qmod-inline-ul) {
    margin-right: 25px;
}

::v-deep(.qmod-trades .company-name-container li.qmod-refresh-control *) {
    font-size: 14px !important;
    font-weight: normal !important;
}
::v-deep(.qmod-trades .company-name-container li.qmod-autoupdate-control *) {
     font-size: 14px !important;
    font-weight: normal !important;
    display: none;
}
::v-deep(.qmod-trades .company-name-container li.qmod-refresh-control .qmod-btn:focus) {
    outline: none;
}

::v-deep(.qmod-trades .company-name-container li.qmod-autoupdate-control .qmod-btn:focus) {
    outline: none;
}

::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button.current) {
    background: linear-gradient(#32364e, #32364e) !important;
    color: #fff !important;
    border: 1px solid #32364e !important;
    box-shadow: none !important;
}

::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button) {
    background-color: #fff !important;
    color: #32364e !important;
    border: 1px solid #32364e !important;
    font: 12px Trebuchet MS, Verdana !important;
    border-radius: 25px !important;
    margin-bottom: 15px !important;
}

::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover),
::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover) {
    background-color: #32364e !important;
    color: #fff !important;
    border: 1px solid #32364e !important;
}

::v-deep(.qmod-trades .dataTables_wrapper .dataTables_info) {
    display: none;
}
::v-deep(.qmod-trades .dataTables_wrapper .dataTables_paginate) {
    float: none;
    text-align: center;
    padding-top: 1em;
}

/* ::v-deep(.highcharts-line-series.highcharts-color-undefined path.highcharts-graph) {
    stroke: #32364e;
} */

/* ::v-deep(.highcharts-series.highcharts-line-series path:first-child) {
    stroke: #32364e;
} */

::v-deep(.highcharts-markers path) {
    stroke: #32364e;
}

::v-deep(.highcharts-series.highcharts-series-0 rect) {
    fill: #33cc99;
    opacity: 0.15;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(.company-name-container) {
    margin-bottom: 8px !important;
}
::v-deep(.company-name-container *) {
    font-size: 24px;
    font-weight: bold;
    color: #32364E;
}

::v-deep(.qmod-entline) {
    margin-bottom: 10px;
}

::v-deep(.qmod-mkt-top) {
    display: flex;
    flex-direction: row;
}
::v-deep(.qmod-mkt-top *) {
    font-size: 1rem !important;
    margin-right: 4px !important;
}
::v-deep(.qmod-mkt-top .qmod-change) {
    display: flex;
    align-items: center;
}
::v-deep(.qmod-mkt-top .qmod-change-icon) {
    font-size: 18px !important;
}
::v-deep(.qmod-mkt-top .qmod-last) {
    font-size: 18px !important;
    color: #32364E !important;
}

::v-deep(.qmod-mkt-mid) {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem !important;
    font-weight: normal !important;
}
::v-deep(.qmod-mkt-mid td) {
    width: 50%;
}
::v-deep(.qmod-mkt-mid span) {
    margin-top: 4px;
}


::v-deep(.qmod-mkt-tradesummary) {
    width: 100% !important;
    margin: 0 0 12px;
    font-size: 1rem !important;
    font-weight: normal !important;
}
::v-deep(.qmod-mkt-tradesummary span) {
    margin-top: 4px;
}

::v-deep(.qmod-trades .qmod-quotehead .qmod-barpct) {
    background-color: #33cc99;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

::v-deep(.qmod-trades .qmod-quotehead .qmod-daylowbar) {
    background-color: #32364E;
    border-radius: 6px;
}
::v-deep(.qmod-head-right) {
    font-size: 12px;
    font-weight: bold;
}

::v-deep(.trades-summary-top-container),
::v-deep(.trades-details-top-container) {
    display: flex;
    justify-content: space-between;
}

::v-deep(#refresh-button:hover),
::v-deep(.qmod-export button:hover) {
    color: #FFFFFF !important;
    background: #33CC99 !important;
    border-color: #33CC99 !important;
}
::v-deep(#refresh-button),
::v-deep(.qmod-export button) {
    height: 35px !important;
    width: fit-content !important;
    padding: 8px 16px !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: 28px !important;
    font-family: 'Trebuchet MS', 'Verdana' !important;
    margin: 0 !important;
    display: inline-flex !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    align-items: center !important;
    vertical-align: bottom !important;
    text-align: center !important;
    overflow: hidden !important;
    position: relative !important;
    box-sizing: border-box !important;
    color: #32364e !important;
    background: #FFFFFF !important;
    border-color: #33CC99 !important;
    border: 1px solid #33CC99 !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    font-weight: normal !important;
}

</style>