<template>
    
    <div style='position: relative;'>
      <LoadingSpinner :showLoading="!chartInitialized"/>
      <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
      <Menu ref="menu" :model="moreMenuItems" :popup="true" />
      <div id="earnings"
        data-qmod-tool="earnings"
        :data-qmod-params='earningsParams'
        class="earnings-qtool">
      </div>
      <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
    </div>
    
</template>

<script>
import SecurityFormatter from '../../common/SecurityFormatter';

import LoadingSpinner from '../common/LoadingSpinner';

import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';


export default {
    name: 'Earnings',
    props: {
        selectedEquity: {
            type: Object,
            required: true,
        },
        showVolume: {
            type: Boolean,
            default: false,
        },
        showArea: {
          type: Boolean,
          default: true,
        }
    },
    computed : {
        earningsParams() {
          let symbolToUse = null;
          if( this.selectedEquity.proxySymbol ) {
              symbolToUse = this.selectedEquity.proxySymbol.replace('/', '.');
          }
          else {
              symbolToUse = SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
          }
            
          return '{"chart":{"colors":["#00897b","#33cc99","#ede348","#f18247","#df404a"],"legend":{"shadow":true}},"resultsPerPage":10,"symbol":"'+symbolToUse+'","showEstimatesOnChart":false}';
        }
    },
   components: {
     LoadingSpinner,
     MoreOptionsMenu,
     SnapshotDialog
   },
    mounted() {
   
      let tmplEle = document.createElement('script');
      tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode(
          `<div class="qmod-ui-tool qmod-earnings">
            <div class="qmod-block-wrapper qmod-eeh" rv-if="eeData.symbolEarnings">
              <h3 class="qmod-heading qmod-hbg">{tplLang.earningshistory | toLang tLang}</h3>`+
              (this.selectedEquity.exchangeSymbol == 'AQL' ? '<div style="margin-bottom:10px;"><small>(Note: Price related data is based on the US company and is in US dollars.)</small></div>': '')
              +`<div class="qmod-chart-js">
                  <div class="ehChart"></div>
              </div>
              <div class="qmod-modifiers">
                  <button class="qmod-btn qmod-darkblue qmod-more-earnings" rv-on-click="data.loadEarningHistory" rv-if="eeData.nextpage" tabindex="0">
                    {tplLang.more | toLang tLang}
                    <div class="qmod-news-loader-newsdate anim-spin fa fa-spinner" style="display:none;"></div>
                  </button>
                  <button class="qmod-btn qmod-darkblue qmod-reset-earnings" rv-on-click="data.reset" style="display:none;" tabindex="0">
                    {tplLang.reset | toLang tLang}
                    <div class="qmod-news-loader-newsdate anim-spin fa fa-spinner" style="display:none;"></div>
                  </button>
              </div>
              <table class="qmod-table qmod-table-hover qmod-earning-history nowrap" width="100%">
                  <thead>
                    <tr>
                        <th class="qmod-control"></th>
                        <th class="qmod-textl">{tplLang.quarterend | toLang tLang}</th>
                        <th class="qmod-textr">$ {tplLang.epsactual | toLang tLang}</th>
                        <th class="qmod-textr">$ {tplLang.epsestimate | toLang tLang}</th>
                        <th class="qmod-textr">{tplLang.numestimates_long | toLang tLang}</th>
                        <th class="qmod-textr">$ {tplLang.surprise | toLang tLang}</th>
                        <th class="qmod-textr">% {tplLang.surprise | toLang tLang}</th>
                        <th class="qmod-textr">{tplLang.date | toLang tLang}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr rv-each-earning="eeData.symbolEarnings.0.earning">
                        <td class="qmod-control"></td>
                        <td class="qmod-textl">{earning.quarterEnd} ({earning.period})</td>
                        <td class="qmod-textr">{earning.actualEps | asQHLast}</td>
                        <td class="qmod-textr">{earning.consensusEps | asQHLast}</td>
                        <td class="qmod-textr">{earning.numOfEstimates}</td>
                        <td class="qmod-textr">{earning.epsSurpriseDollar | asQHLast}</td>
                        <td class="qmod-textr" rv-html="earning.epsSurprisePercent | numeraljs '0.00' | asQHLast | postFix '%'"> </td>
                        <td class="qmod-textr">{earning.reportDate.0}</td>
                    </tr>
                  </tbody>
              </table>
            </div>
        </div>
        <div class="qmod-tool-wrap" rv-unless="data.datatype | = 'equity'">
            <div class="qmod-block-wrapper">
              <div class="qmod-invalid" rv-if="data | isValidSymbol">{tplLang.nodata| toLang tLang}: {data.symbolstring}</div>
              <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol | toLang tLang}: {data.symbolstring}</div>
            </div>
        </div>
      </div>`);
        tmplEle.appendChild(txt);
      document.getElementById('earnings').appendChild(tmplEle);
      
      document.getElementById('earnings').addEventListener('qmod-after-template-bind',  () => {
          console.debug('earnings template bound**************')
          this.chartInitialized = true;
          
      },false) // {once:true}
      try {
        window.qMod.loadTool('earnings'); 
      }catch(err){
        console.log("err loading qmod ", err);
      }
        
      
    },
    activated() {
        console.debug(this.$options.name + " activated");
        setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 3000); 
       
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
        this.displaySnapshot = false;
    },

    data() {
      return {
          chartInitialized: false,
          displaySnapshot: false,
          snapshotTarget: null,
          moreMenuItems: [
              {
                label: 'Take Snapshot',

                icon: 'pi pi-camera',
                command: () => {
                  this.openSnapshotDialog();
                }
              },    
            ],
      }
    },
    
    methods: {
        
       toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementsByClassName('earnings-qtool')[0];
            this.displaySnapshot = true;
        },
       
    },
   
   
}
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 10px;
}

::v-deep( svg.highcharts-root) {
    font-family: "Trebuchet MS", "Verdana" !important;
  font-size: 1.3vh !important;
  overflow: visible;
}

::v-deep(.qmod-ui-tool) {
  font-size: 1.5vh;
}
::v-deep(.qmod-btn) {
  font-size: 1.5vh;
  padding: 5px;
}

::v-deep(h3.qmod-heading) {
  font-size: 2.2vh;
}

::v-deep(.qmod-quotehead) {
  display:none;
}

 ::v-deep(.qmod-heading.qmod-hbg){
  background-color: inherit;
  font-size: 24px;
  color: #32364E;
  font-family: "Trebuchet MS", Verdana;
  font-weight: 600;
  padding: 0px;
  /* color: #d1d4dc !important; */
}

::v-deep(.qmod-earnings .qmod-tool-wrap) {
  margin-top: 0;
}
::v-deep(.qmod-earnings .qmod-chart-js) {
  bottom: -30px;
}
::v-deep(.qmod-earnings .qmod-chart-js > div) {
  position: relative;
  max-width: 98%;
  margin: 0 auto 0 0;
  right: 10px;
}

::v-deep(.qmod-block-wrapper) {
  margin-bottom: 0;
}
/* 
 ::v-deep(.qmod-chart-js) {
  background-color: #1f2d40;
}
::v-deep(.qmod-ui-tool .qmod-simplechart) {
  background-color: #1f2d40;
}
::v-deep(.qmod-block-wrapper) {
  background-color: #1f2d40;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .highcharts-container) {
  border-color: #17212f;
} 
::v-deep(.qmod-heading .qmod-hbg h2){
  background-color: #1f2d40;
  color: #d1d4dc !important;
}
::v-deep(.qmod-analyst-ratings .qmod-heading){
   color: #d1d4dc !important;
}

::v-deep( .qmod-hbg ){ 
  background-color: #1f2d40;
 
}

::v-deep( .qmod-ui-tool .qmod-simplechart) {
  background-color: #1f2d40;
}*/


::v-deep(.highcharts-container ) {
  overflow: visible !important;
}


 ::v-deep(.qmod-modifiers) {
   margin: 12px 0px 20px;
 }
 ::v-deep(.qmod-modifiers .qmod-btn) {
    position: static;
    background:#fff;
    color: #32364e;
    border: 1px solid #32364e;
    box-shadow: none;
 }
 ::v-deep(.qmod-modifiers .qmod-btn:hover) {
    position: static;
    background:#32364E;
    color: #fff;
 }


/* highcharts EPS graphical stylings */
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-column-series.highcharts-tracker ) {
  opacity: 0.1;
}
::v-deep(.highcharts-legend .highcharts-legend-item.highcharts-series-0 path.highcharts-graph),
::v-deep(.highcharts-series-group .highcharts-series.highcharts-series-0 path.highcharts-graph) {
  stroke: rgb(51, 204, 153);
}
::v-deep(.highcharts-legend .highcharts-legend-item.highcharts-series-0 path.highcharts-point),
::v-deep(.highcharts-series-group .highcharts-markers.highcharts-series-0 path.highcharts-point) {
  fill: rgb(51, 204, 153);
}
::v-deep(.highcharts-legend .highcharts-legend-item.highcharts-series-1 path.highcharts-graph),
::v-deep(.highcharts-series-group .highcharts-series.highcharts-series-1 path.highcharts-graph) {
  stroke: #A6A6A6;
}
::v-deep(.highcharts-legend .highcharts-legend-item.highcharts-series-1 path.highcharts-point),
::v-deep(.highcharts-series-group .highcharts-markers.highcharts-series-1 path.highcharts-point) {
  fill: #A6A6A6;
}
::v-deep(.highcharts-tooltip text tspan:first-child) {
  font-weight: 600;
  font-size: 12px !important;
  color: #32364E;
}
::v-deep(.highcharts-tooltip text tspan:nth-child(2)) {
  fill: rgb(51, 204, 153) !important;
}
::v-deep(.highcharts-tooltip text tspan:nth-child(5)) {
  fill: #a6a6a6 !important;
}
::v-deep(.highcharts-tooltip .highcharts-tooltip-box:last-of-type) {
  stroke: #32364E;
  fill: #FFFFFF;
}


::v-deep(svg ){
  pointer-events: none;  /*removes anoying browser tooltip*/
}

::v-deep(.highcharts-axis.highcharts-yaxis .highcharts-axis-title),
::v-deep(.highcharts-axis.highcharts-xaxis .highcharts-axis-title) {
  display: none;
}
::v-deep(.highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line){
  opacity: 0.7;
  stroke-width: 1px;
  stroke:#BFBFBF;
  stroke-dasharray: 1,5;
} 
::v-deep(.highcharts-axis-labels.highcharts-yaxis-labels text) {
  color: #7f7f7f !important;
  fill: #7F7F7F !important;
  font-size: 10px !important;
  /* transform: translate(100%, 0); */
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels text) {
  color: #7f7f7f !important;
  fill: #7F7F7F !important;
  font-size: 10px !important;
}

::v-deep(.highcharts-point) {
  rx: 3 !important;
    ry: 3 !important;;
    width: 2vh !important;
 
}

::v-deep(.highcharts-tooltip) {
  /* display:none; */
  /* background-color: #1f2d40; */
  border: none !important;
}
::v-deep(.highcharts-tooltip-box) {
  /* background-color: #1f2d40 !important;
  fill: #1f2d40 !important; */
  border: none !important;
  /* color: #d1d4dc; */
}
/* 
::v-deep(.highcharts-tooltip > text) {
   color: #d1d4dc !important;
   fill:  #d1d4dc !important;
} */
::v-deep(.highcharts-legend) {
  /* transform: translate(calc(50% - 9em), -7.5%); */
}
::v-deep(.highcharts-legend-box) {
  stroke: none;
  filter: none;
}
::v-deep(.highcharts-legend-item > text) {
   font-size: 1.3vh !important;
   font-weight: normal !important;
}

.earnings-qtool ::v-deep(.dataTables_wrapper) {
  margin: 0px;
}
.earnings-qtool ::v-deep(.qmod-earnings table.qmod-table.dataTable>thead>tr>th) {
  color: #999;
}
.earnings-qtool ::v-deep(.qmod-earnings .dataTables_wrapper .dataTables_info) {
  color: black;
}
.earnings-qtool ::v-deep(.qmod-earnings .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
.earnings-qtool ::v-deep(.qmod-earnings .dataTables_wrapper .dataTables_paginate .paginate_button.current) {
    background: linear-gradient(#32364e, #32364e);
    color: #fff !important;
    border: 1px solid #32364e;
    box-shadow: none;
}
.earnings-qtool ::v-deep(.qmod-earnings .dataTables_wrapper .dataTables_paginate .paginate_button) {
    background-color: #fff;
    color: #32364e !important;
    border: 1px solid #32364e;
    font: 12px Trebuchet MS, Verdana !important;
    border-radius: 25px;
}
.earnings-qtool ::v-deep(.qmod-earnings .dataTables_wrapper .dataTables_paginate .paginate_button:hover) {
    background-color: #32364e !important;
    color: #fff !important;
    border: 1px solid #32364e;
}

@media (max-width: 760px) {
  ::v-deep(.qmod-earnings .qmod-chart-js > div) {
    right: 30px;
  }
}
</style>