<template>
    <div>
        <div class='tab-content' v-if='canAccessFeature'>
            <keep-alive>
                <Filings :selectedSymbol="selectedSymbol" v-observe-visibility="visibilityChanged" v-if="hasFeatureEnabled"/>
            </keep-alive>
        </div>

        <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="bodyText" @cancel-upgrade="cancelUpgrade"/>
    </div>
</template>

<script>
import SecurityFormatter from '../../common/SecurityFormatter';
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import { mapState } from 'vuex';
import UserUtils from '../../utilities/UserUtils';

import Filings from '../QuoteMedia/Filings.vue';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal.vue';

export default {
    name: 'FilingsView',
    emits: ['access-denied'],
    props: {
        hasFeatureEnabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            justMounted: false,
            active: false,
            currentSymbol: null,
            bodyText: "To get full access to all North American financial filings and the other premium features below, upgrade to premium."
        }
    },

    components: {
        Filings, UpgradeToPremiumModal
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
			let sym =  SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
            if( this.selectedEquity.exchangeSymbol == 'AQL') {
                return this.selectedEquity.proxySymbol.replace('/', '.');
            }
            else {
                return sym;
            }
		},
        canAccessFeature() {
            return this.hasFeatureEnabled && UserUtils.isPremiumSubscriber();
        }
    },
    watch: {
		selectedEquity() {
            console.log(this.$options.name + " selected equity watcher called");
            if ( !this.justMounted  ) {
                if( this.currentSymbol !== this.selectedSymbol && this.$route.path === '/equity/filings') {
                    console.log("about to call qmod loadtools");
                    this.currentSymbol = this.selectedSymbol;    
                    window.qMod.loadTool('filings-qtool'); 
                }
                
			}
		
		},
         $route(newRoute, oldRoute) { //eslint-disable-line
            if( newRoute.fullPath =='/equity/filings') {
                if (this.canAccessFeature) {
                    if( this.currentSymbol !== this.selectedSymbol) {
                       
                            this.currentSymbol = this.selectedSymbol;
                             window.qMod.loadTool('filings-qtool'); 
                       
                    } else {
                        window.dispatchEvent(new Event('resize'))
                    }
                 } else {
                    this.$refs.upgradeToPremiumModal.open();
                }
            }
        },
	},
    mounted() {
		console.log(this.$options.name + " mounted");
		this.justMounted = true;

        if (this.canAccessFeature) {
            this.currentSymbol = this.selectedSymbol;
            //need to load news once this way as it was previously loaded from dashboard
             window.qMod.loadTool('filings-qtool'); 
        }
		
	},
	activated() {
		this.active = true;
		console.log(this.$options.name + " activated");
		if( !this.justMounted){
            if( this.currentSymbol !== this.selectedSymbol) {
                 window.qMod.loadTool('filings-qtool'); 
            }
        }
		this.justMounted = false;
	},
	deactivated() {
        console.log(this.$options.name + " deactivated");
		this.justMounted = false;
		this.active = false;
	},

    methods: {
        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible) {
                window.dispatchEvent(new Event('resize'));
            }
        },

        cancelUpgrade() {
            this.$emit('access-denied');
        }
    }
}
</script>