<template>
    <div>
        <div class="tab-content" v-if='canAccessFeature'>
            <div class="main-container" v-observe-visibility="visibilityChanged" v-if="hasFeatureEnabled">
                
                    <keep-alive>
                        <PerformanceOverview :selectedSymbol="selectedSymbol" />
                    </keep-alive>
                
                
                <AttributionFooter authors="quoteMedia" />
            </div>
        </div>

        <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="bodyText" @cancel-upgrade="cancelUpgrade" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import SecurityFormatter from '../../common/SecurityFormatter';
import UserUtils from '../../utilities/UserUtils';

import PerformanceOverview from '../QuoteMedia/PerformanceOverview';
// import PerformanceAnnualInfo from '../QuoteMedia/PerformanceAnnualInfo';
// import PerformancePriceInfo from '../QuoteMedia/PerformancePriceInfo';
// import PerformancePriceChart from '../QuoteMedia/PerformancePriceChart';
// import PerformanceVolumeChart from '../QuoteMedia/PerformanceVolumeChart';
// import PerformanceFloatChart from '../QuoteMedia/PerformanceFloatChart';
// import PerformanceShareholderChart from '../QuoteMedia/PerformanceShareholderChart';
// import PerformanceInstitutionalChart from '../QuoteMedia/PerformanceInstitutionalChart';

import AttributionFooter from '../footer/AttributionFooter.vue';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal';

export default {
    name: 'Performance',
    emits: ['access-denied'],
    props: {
        hasFeatureEnabled: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            justMounted: false,
            active: false,
            currentSymbol: null,
            bodyText: 'To get full access to granular performance data for all securities and the other premium features below, upgrade to premium.',
        };
    },

    components: {
        PerformanceOverview,
        // PerformanceAnnualInfo,
        // PerformancePriceInfo,
        // PerformancePriceChart,
        // PerformanceVolumeChart,
        // PerformanceFloatChart,
        // PerformanceShareholderChart,
        // PerformanceInstitutionalChart,
        AttributionFooter,
        UpgradeToPremiumModal,
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
            return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
        },

        canAccessFeature() {
            return this.hasFeatureEnabled && UserUtils.isPremiumSubscriber();
        }
    },

    watch: {
        selectedEquity() {
            console.log(this.$options.name + ' selected equity watcher called');
            if (!this.justMounted) {
                if (this.currentSymbol != this.selectedSymbol && this.$route.path == '/equity/performance') {
                    console.log('performance about to call qmod loadtools');
                    this.currentSymbol = this.selectedSymbol;
                    this.loadTools();
                }
            }
        },
        $route(newRoute, oldRoute) { //eslint-disable-line
            if (newRoute.fullPath == '/equity/performance') {
                if (this.canAccessFeature) {
                    if (this.currentSymbol != this.selectedSymbol) {
                       
                            this.currentSymbol = this.selectedSymbol;
                            this.loadTools();
                       
                    } else {
                        window.dispatchEvent(new Event('resize'));
                    }
                } else {
                    this.$refs.upgradeToPremiumModal.open();
                }
                
            }
        },
    },

    mounted() {
        console.log(this.$options.name + ' mounted');
        this.justMounted = true;

        if (this.canAccessFeature) {
            this.currentSymbol = this.selectedSymbol;

            this.loadTools();
        } 
    },

    activated() {
        this.active = true;
        console.log(this.$options.name + ' activated');
        if (!this.justMounted) {
            if (this.currentSymbol != this.selectedSymbol) {
                this.loadTools();
            }
        }
        this.justMounted = false;
    },

    deactivated() {
        console.log(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.active = false;
    },

    methods: {

        loadTools() {
            window.qMod.loadTool('performanceOverview');
            // window.qMod.loadTool('performancePriceInfo');
            // window.qMod.loadTool('performancePriceChart');
            // window.qMod.loadTool('performanceVolumeChart');
            // window.qMod.loadTool('performanceFloatChart');
            // window.qMod.loadTool('performanceShareholderChart');
            // window.qMod.loadTool('performanceInstitutionalChart');
           
        },

        visibilityChanged(isVisible, entry) { // eslint-disable-line
            if (isVisible) {
                window.dispatchEvent(new Event('resize'));
            }
        },

        cancelUpgrade() {
            this.$emit('access-denied');
        },
    },
};
</script>

<style scoped>
::v-deep(.p-grid) {
    margin: 0px;
}

::v-deep(.card) {
    margin-bottom: 16px;
    height: 96%;
}

::v-deep(.more-options) {
    position: absolute;
    right: 0px;
    top: 0px;
}

::v-deep(.chart-header) {
    font-size: 24px !important;
    color: #32364e !important;
    font-weight: bold !important;
    padding: 0px !important;
    margin-bottom: 8px !important;
}



::v-deep(.qmod-quotehead) {
    margin: 0px !important;
}

::v-deep(h2),
::v-deep(h3),
::v-deep(div),
::v-deep(text),
::v-deep(tspan),
::v-deep(.qmod-ui-tool) {
    font-family: 'Trebuchet MS', Verdana !important;
}

::v-deep(.qmod-ui-tool .qmod-chart-js div) {
    border: 0;
}

::v-deep(.qmod-ui-tool .highcharts-legend-box) {
    display: none;
}

::v-deep(.qmod-ui-tool .highcharts-legend-item text) {
    font-weight: 100 !important;
}

::v-deep(.pure-g.qmod-marr),
::v-deep(.pure-g.qmod-marl) {
  padding: 0px 40px 0 10px !important;
}
</style>