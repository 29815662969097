<template>
    <p id='base_text'>{{text}}</p>
</template>

<script>
export default {
    name: 'ExpandableText',
    props: {
        baseText: {
            type: String,
            required: true
        },
        characterLimit: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            text: this.baseText,
            limit: this.characterLimit
        }
    },

    mounted() {
        let textDiv = document.getElementById('base_text');
        let actualText = textDiv.innerText;

        let remainingTextNode = document.createTextNode(actualText.substring(0, this.characterLimit));
        textDiv.innerHTML = ''; // Empty the content (profile description text) within it's container div
        textDiv.appendChild(remainingTextNode);

        let seeMoreLinkElement = document.createElement('a');
        let seeMoreTextNode = document.createTextNode(' ...See More');
        seeMoreLinkElement.appendChild(seeMoreTextNode);

        let moreTextElement = document.createElement('span');
        moreTextElement.className = 'more-text';
        moreTextElement.style.display = 'none'; // Ensures that the surplus of text is hidden 
        
        let moreTextNode = document.createTextNode(actualText.substring(this.characterLimit, (actualText.trim()).length));
        moreTextElement.appendChild(moreTextNode);

        let seeLessLinkElement = document.createElement('a');
        let seeLessTextNode = document.createTextNode(' See Less');
        seeLessLinkElement.appendChild(seeLessTextNode);

        // If clicking on see less link, it will hide the surplus of text as well as the link itself, then show the see more link instead
        seeLessLinkElement.addEventListener('click', function() {
            moreTextElement.style.display = 'none';
            seeLessLinkElement.style.display = 'none';
            seeMoreLinkElement.style.display = 'inline';
        })

        // Insert the see less link at the end of the element holding the surplus of text
        moreTextElement.insertAdjacentElement('beforeend', seeLessLinkElement);

        // If clicking on see more link, it will display the surplus of text and the see less link, plus hide the see more link 
        seeMoreLinkElement.addEventListener('click', function() {
            moreTextElement.style.display = 'inline';
            seeMoreLinkElement.style.display = 'none';
            seeLessLinkElement.style.display = 'inline';

        })

        textDiv.appendChild(seeMoreLinkElement);
        textDiv.appendChild(moreTextElement);

    }
}
</script>

<style scoped>
    ::v-deep(a) {
        color: rgb(51, 204, 153);
        font-weight: 600;
        cursor: pointer;
    }
</style>