<template>
    <div style="position: relative">
        <LoadingSpinner :showLoading="!chartInitialized" />
        <MoreOptionsMenu class="more-options" @click="toggleMenu" v-show="chartInitialized" />
        <Menu ref="menu" :model="moreMenuItems" :popup="true" />
        <div id="performanceOverview" data-qmod-tool="shareinfoperformance" :data-qmod-params="performanceParams" class="shareinfoperformance-qtool" ref="performanceOverview" />
        <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'PerformanceOverview',

    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
    },
    computed: {
        performanceParams() {
            return JSON.stringify({
                lang: 'en',
                chart: {
                    // colors: ['#32364e', '#33cc99', '#ea5c5c', '#32364e', '#fff2cc'],
                    // backgroundColor: '#ffffff',
                    // legend: { textColor: '#434348', backgroundColor: '#ffffff', textColorHover: '#000000', shadow: true },
                    // yAxis: { titleTextColor: '#cccccc', labelTextColor: '#434348' },
                    // xAxis: { titleTextColor: '#cccccc', labelTextColor: '#434348' },

                    colors: ['#33cc99', '#32364e', '#ea5c5c', '#32364e', '#fff2cc'],
                    backgroundColor: '#ffffff',
                    legend: { textColor: '#434348', backgroundColor: '#ffffff', textColorHover: '#000000', shadow: true },
                    yAxis: { titleTextColor: '#cccccc', labelTextColor: '#434348' },
                    xAxis: { titleTextColor: '#cccccc', labelTextColor: '#434348' },
                },

                   
                showLogo: false,
                lowHigh: false,
                symbol: this.selectedSymbol,
                autoUpdateEnabled : false,
            });
        },
    },
    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog,
    },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute('type', 'text/template');

        let txt = document.createTextNode(
            `<div class="qmod-ui-tool qmod-shareinfo">
                
                
                <div class="qmod-tool-wrap" rv-if="shareInfo.shareinfo">
                   
                    <div class="qmod-block-wrapper qmod-si-annualinfo card" rv-if="shareInfo.shareinfo.annualinfo">
                        <h3 class="chart-header">{tplLang.annualinformation| toLang tLang}</h3>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.latestfiscaldate| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.annualinfo.latestfiscaldate}</div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.latestfiscaleps| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.annualinfo.latestfiscalEPS | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.latestfiscalrevenue| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.annualinfo.latestfiscalrevenue | numeraljs '0.00a' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.latestfiscaldividendspershare| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.annualinfo.latestfiscaldividendspershare | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-block-wrapper qmod-si-priceinfo card" rv-if="shareInfo.shareinfo.priceinfo">
                        <h3 class="chart-header">{tplLang.princeinformation| toLang tLang}</h3>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.52wkh| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.weeks52high | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.52wklw| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.weeks52low | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.52wkchng| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.priceinfo.weeks52change | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.10dma| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.day10movingavg | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.21dma| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.day21movingavg | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.50dma| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.day50movingavg | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.100dma| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.day100movingavg | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.200dma| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.day200movingavg | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.alpha| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.alpha | numeraljs '0.0[0000]' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.beta| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.beta | numeraljs '0.0[0000]' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.rsquared| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.r2 | numeraljs '0.0[0000]' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.standarddeviation| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.stddev | numeraljs '0.0[0000]' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.period| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.priceinfo.periods | numeraljs '0' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-block-wrapper qmod-si-pricechange card" rv-if="shareInfo.shareinfo.pricechange">
                        <h3 class="chart-header">{tplLang.pricechange| toLang tLang}</h3>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-3">
                            <div class="qmod-chart-js qmod-marr">
                                <div class="pcDay"></div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.7day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day7 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day7pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.21day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day21 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day21pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.30day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day30 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day30pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.90day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day90 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day90pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.180day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day180 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day180pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.200day| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.day200 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.day200pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-3">
                            <div class="qmod-chart-js qmod-marl">
                                <div class="pcTdate"></div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.today| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{data.pricedata.change | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="data.pricedata.changepercent | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.monthtodate| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.monthtodate | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.monthtodatepct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.quartertodate| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.quartertodate | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.quartertodatepct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.yeartodate| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.yeartodate | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.yeartodatepct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-3">
                            <div class="qmod-chart-js qmod-marr">
                                <div class="pcYear"></div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.1yr | toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.year1 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.year1pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.3yr | toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.year3 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.year3pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.5yr | toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.year5 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.year5pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-1-3">
                                    <div class="qmod-label">{tplLang.10yr | toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.pricechange.year10 | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.pricechange.year10pct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'">   </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-block-wrapper qmod-si-shareinformation card" rv-if="shareInfo.shareinfo.shareinformation">
                        <h3 class="chart-header">{tplLang.shareinformation| toLang tLang}</h3>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="qmod-chart-js qmod-marr">
                                <div class="avgVol"></div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.intradayvolume| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{data.pricedata.sharevolume | numeraljs '0.00a' '&mdash;' tLang}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.avpd10| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.avgvol10days | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.avpd20| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.avgvol20days | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.avpd30| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.avgvol30days | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-separator qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.avpd50| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.avgvol50days | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="qmod-chart-js qmod-marl">
                                <div class="outFloat"></div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.totalshares| toLang tLang}</div>
                                </div>
                                <!--div.pure-u-2-3: div.qmod-label {tplLang.outstanding| toLang tLang}-->
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{data.fundamental.totalsharesoutstanding | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                                <!--div.pure-u-1-3: div.qmod-desc.qmod-textr {data.fundamental.sharesoutstanding | numeraljs '0.00a' '&mdash;' tLang '0'}-->
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.float| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.float | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl" rv-if="shareInfo.shareinfo.shareinformation.pctfloat">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.percentfloat| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.shareinformation.pctfloat | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl" rv-if="shareInfo.shareinfo.shareinformation.shortint">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.shortinterest| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.shortint | numeraljs '0.00a' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl" rv-if="shareInfo.shareinfo.shareinformation.shortintratio">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.shortinterestratio| toLang tLang} ({shareInfo.shareinfo.shareinformation.shortdate})</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.shareinformation.shortintratio | numeraljs '0.00' '&mdash;' tLang '0.00'}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-block-wrapper qmod-si-holdings card">
                        <h3 class="chart-header">{tplLang.holdings| toLang tLang}</h3>
                        <div class="pure-g">
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="qmod-chart-js qmod-marr">
                                <div class="holdingPie"></div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.instholdings| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.holdings.instituteholdingspct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.tinstheld| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.totalheld | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.institutions| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.institutions | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.noncorphold| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr" rv-html="shareInfo.shareinfo.holdings.insiderholdingspct | numeraljs '0.00' '&amp;mdash;' tLang '0.00' | postFix '%' | str_replace '&amp;mdash;%' '&amp;mdash;'"></div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marr">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.totalinsideheld| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.insidersharesowned | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            </div>
                            <div class="pure-u-1 pure-u-md-1-2">
                            <div class="qmod-chart-js qmod-marl">
                                <div class="holdingBar"></div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.instboughtprev3mo| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.instituteboughtprev3mos | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.instsoldprev3mo| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.institutesoldprev3mos | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.insideboughtprev3mo| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.insiderboughtprev3mos | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            <div class="pure-g qmod-marl">
                                <div class="pure-u-2-3">
                                    <div class="qmod-label">{tplLang.insidesoldprev3mo| toLang tLang}</div>
                                </div>
                                <div class="pure-u-1-3">
                                    <div class="qmod-desc qmod-textr">{shareInfo.shareinfo.holdings.insidersoldprev3mos | numeraljs '0.00a' '&mdash;' tLang '0'}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="qmod-tool-wrap" rv-unless="shareInfo.shareinfo">
                    <h2 class="qmod-heading qmod-hbg">{tplLang.si_toolname| toLang tLang}</h2>
                    <div class="qmod-block-wrapper">
                        <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol| toLang tLang}: {data.symbolstring}</div>
                        <div class="qmod-invalid" rv-if="data | isValidSymbol">{tplLang.nodata| toLang tLang}: {data.symbolstring}</div>
                    </div>
                </div>
                </div>`
            
        );
        tmplEle.appendChild(txt);
        this.$refs.performanceOverview.appendChild(tmplEle);

        this.$refs.performanceOverview.addEventListener(
            'qmod-after-template-bind',
            () => {
                console.debug('performance visuals template bound**************');
                this.chartInitialized = true;
            },
            false
        ); //should ensure it is fired only once  {once:true}

        this.justMounted = true;
    },
    activated() {
        console.debug(this.$options.name + ' activated');
    },
    deactivated() {
        console.debug(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
        return {
            chartInitialized: false,
            justMounted: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
                {
                    label: 'Take Snapshot',

                    icon: 'pi pi-camera',
                    command: () => {
                        this.openSnapshotDialog();
                    },
                },
            ],
        };
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = this.$refs.performanceOverview.getElementsByClassName('qmod-shareinfo')[0];
            this.displaySnapshot = true;
        },
    },
};
</script>

<style scoped>
::v-deep(.qmod-head-left) {
    margin: 0 !important;
}


::v-deep(.card){
    margin-bottom:16px;
}

::v-deep(.company-name-container) {
    margin-bottom: 8px !important;
}
::v-deep(.company-name-container *) {
    font-size: 24px;
    font-weight: bold;
    color: #32364E;
}

::v-deep(.qmod-mkt-top) {
    display: flex;
    flex-direction: row;
}
::v-deep(.qmod-mkt-top *) {
    font-size: 1rem !important;
    margin-right: 4px !important;
}
::v-deep(.qmod-mkt-top .qmod-change) {
    display: flex;
    align-items: center;
}
::v-deep(.qmod-mkt-top .qmod-change-icon) {
    font-size: 18px !important;
}
::v-deep(.qmod-mkt-top .qmod-last) {
    font-size: 18px !important;
    color: #32364E !important;
}

::v-deep(.qmod-mkt-mid) {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem !important;
    font-weight: normal !important;
}
::v-deep(.qmod-mkt-mid td) {
    width: 50%;
}
::v-deep(.qmod-mkt-mid span) {
    margin-top: 4px;
}

::v-deep(.qmod-mkt-tradesummary) {
    width: 100% !important;
    margin: 0 0 12px;
    font-size: 1rem !important;
    font-weight: normal !important;
}
::v-deep(.qmod-mkt-tradesummary span) {
    margin-top: 4px;
}

::v-deep(.qmod-shareinfo .qmod-quotehead .qmod-barpct) {
    background-color: #33cc99;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

::v-deep(.qmod-shareinfo .qmod-quotehead .qmod-daylowbar) {
    background-color: #32364E;
    border-radius: 6px;
}
::v-deep(.qmod-head-right) {
    font-size: 12px;
    font-weight: bold;
}

::v-deep(.qmod-head-left) {
    font-size: 12px;
}

::v-deep(.trades-summary-top-container),
::v-deep(.trades-details-top-container) {
    display: flex;
    justify-content: space-between;
}

::v-deep(#refresh-button:hover),
::v-deep(.qmod-export button:hover) {
    color: #FFFFFF !important;
    background: #33CC99 !important;
    border-color: #33CC99 !important;
}
</style>