<template>
    <LoadingSpinner :showLoading="!chartInitialized"/>
    <div style="position: relative;" v-show="chartInitialized">
      <MoreOptionsMenu class='more-options' @click="toggleMenu" />
      <Menu ref="menu" :model="moreMenuItems" :popup="true" />
      <div id="fullEarnings" ref="fullEarnings"
        data-qmod-tool="earnings"
        :data-qmod-params='earningsParams'
        class="consensus-eps-estimates-chart-qtool">
      </div>
      <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'FullEarnings',

    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
         selectedEquity: {
            type: Object,
            required: true,
        }
    },
    computed : {
        earningsParams() {
          
          return '{"chart":{"colors":["#00897b","#33cc99","#ede348","#f18247","#df404a"],"legend":{"shadow":true}},"resultsPerPage":25,"showLogo":false,"lowHigh":false,"symbol":"'+this.selectedSymbol+'","showEstimatesOnChart":false}';
        }
    },
   components: {
     LoadingSpinner,
     MoreOptionsMenu,
     SnapshotDialog
   },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
          let txt = document.createTextNode(
            `<div class="qmod-ui-tool qmod-earnings">
              
              
              
              <div class="qmod-tool-wrap" rv-if="data.datatype | = 'equity'">
                  <div class="qmod-block-wrapper qmod-ectp" rv-unless="shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.currentTarget">
                    <div class="qmod-no-consensus-data">{tplLang.noconsensusdata | toLang tLang}</div>
                  </div>
                  <div class="qmod-block-wrapper qmod-ectp card" rv-if="shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.currentTarget">
                    <h3 class="qmod-heading">{tplLang.currenttargetprice | toLang tLang}</h3>
                    <div class="pure-g">
                        <div class="pure-u-1 pure-u-md-1-2">
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.hightargetprice | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.currentTarget | asQHLast}</div>
                              </div>
                          </div>
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.meantargetprice | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.targetPriceHistory.meanTargetPriceEstimate.currentTarget| asQHLast}</div>
                              </div>
                          </div>
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.lowtargetprice | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.currentTarget | asQHLast}</div>
                              </div>
                          </div>
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.standarddeviation | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.targetPriceHistory.standardDeviation.currentTarget| asQHLast}</div>
                              </div>
                          </div>
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.numberofestimates | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.numberofestimates.NumEstimatesInConsensus}</div>
                              </div>
                          </div>
                          <div class="pure-g">
                              <div class="pure-u-2-3">
                                <div class="qmod-label">{tplLang.dateofmostrecentestimate | toLang tLang}</div>
                              </div>
                              <div class="pure-u-1-3">
                                <div class="qmod-desc textr">{shareInfo.earningsEstimates.targetPriceHistory.dateMostRecentEstimate.currentTarget}</div>
                              </div>
                          </div>
                        </div>
                        <div class="pure-u-1 pure-u-md-1-2">
                          <div class="qmod-chart-js">
                              <div class="ctpChart"></div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="qmod-block-wrapper qmod-etph card" rv-if="shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.currentTarget">
                    <h3 class="qmod-heading">{tplLang.targetpricehistory | toLang tLang}</h3>
                    <div class="qmod-chart-js">
                        <div class="tphChart"></div>
                    </div>
                    <table class="qmod-table qmod-table-hover qmod-pricehistory-table nowrap" width="100%">
                        <thead>
                          <tr>
                              <th class="qmod-control"></th>
                              <th class="qmod-textl"></th>
                              <th class="qmod-textr">{tplLang.current | toLang tLang}</th>
                              <th class="qmod-textr">{tplLang.oneweekago | toLang tLang}</th>
                              <th class="qmod-textr">{tplLang.twoweeksago | toLang tLang}</th>
                              <th class="qmod-textr">{tplLang.threeweeksago | toLang tLang}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.hightargetpriceestimate | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.currentTarget | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.weeks1Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.weeks2Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.highTargetPriceEstimate.weeks3Ago | asQHLast}</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.lowtargetpriceestimate | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.currentTarget | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.weeks1Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.weeks2Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.lowTargetPriceEstimate.weeks3Ago | asQHLast}</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.meantargetpriceestimate | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.meanTargetPriceEstimate.currentTarget | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.meanTargetPriceEstimate.weeks1Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.meanTargetPriceEstimate.weeks2Ago | asQHLast}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.targetPriceHistory.meanTargetPriceEstimate.weeks3Ago | asQHLast}</td>
                          </tr>
                          <tr>
                              <td class="qmod-control">
                              <td class="qmod-textl">{tplLang.numberofestimates | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.numberofestimateshistory.currentTarget}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.numberofestimateshistory.weeks1Ago}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.numberofestimateshistory.weeks2Ago}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.numberofestimateshistory.weeks3Ago}</td>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="qmod-block-wrapper qmod-ecest card" rv-if="shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst">
                    <h3 class="qmod-heading">{tplLang.consensusepsestimates | toLang tLang}</h3>
                    <div class="qmod-chart-js">
                        <div class="ceeChart"></div>
                    </div>
                    <table class="qmod-table qmod-table-hover qmod-pricehistory-table nowrap" width="100%">
                        <thead>
                          <tr>
                              <th class="qmod-control"></th>
                              <th class="qmod-textl">&nbsp;</th>
                              <th class="qmod-textr">{tplLang.qtr | toLang tLang} 1 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr1.monthYear})</th>
                              <th class="qmod-textr">{tplLang.qtr | toLang tLang} 2 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr2.monthYear})</th>
                              <th class="qmod-textr">{tplLang.FY | toLang tLang} 1 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr1.monthYear})</th>
                              <th class="qmod-textr">{tplLang.FY | toLang tLang} 2 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr2.monthYear})</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.currentestimate | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr2.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr2.content | asQHLast }</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.sevendaysago | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.qr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.qr2.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.fr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.fr2.content | asQHLast }</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.thirtydaysago | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.qr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.qr2.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.fr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.fr2.content | asQHLast }</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.sixtydaysago | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.qr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.qr2.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.fr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.fr2.content | asQHLast }</td>
                          </tr>
                          <tr>
                              <td class="qmod-control"></td>
                              <td class="qmod-textl">{tplLang.ninetydaysago | toLang tLang}</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.qr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.qr2.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.fr1.content | asQHLast }</td>
                              <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.fr2.content | asQHLast }</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  
              </div>
              <div class="qmod-tool-wrap" rv-unless="data.datatype | = 'equity'">
                  <div class="qmod-block-wrapper">
                    <div class="qmod-invalid" rv-if="data | isValidSymbol">{tplLang.nodata| toLang tLang}: {data.symbolstring}</div>
                    <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol | toLang tLang}: {data.symbolstring}</div>
                  </div>
              </div>
            </div>`
            // `<div class="qmod-ui-tool qmod-earnings">
            //   <div class="qmod-tool-wrap" rv-if="data.datatype | = 'equity'">
            //       <div class="qmod-block-wrapper qmod-ecest" rv-if="shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst">
            //         <h3 class="qmod-heading">EPS Estimates</h3>
            //         <div class="qmod-chart-js">
            //             <div class="ceeChart"></div>
            //         </div>
            //         <table class="qmod-table qmod-table-hover qmod-pricehistory-table nowrap" width="100%">
            //           <thead>
            //               <tr>
            //                 <th class="qmod-control"></th>
            //                 <th class="qmod-textl">&nbsp;</th>
            //                 <th class="qmod-textr">{tplLang.qtr | toLang tLang} 1 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr1.monthYear})</th>
            //                 <th class="qmod-textr">{tplLang.qtr | toLang tLang} 2 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr2.monthYear})</th>
            //                 <th class="qmod-textr">{tplLang.FY | toLang tLang} 1 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr1.monthYear})</th>
            //                 <th class="qmod-textr">{tplLang.FY | toLang tLang} 2 ({shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr2.monthYear})</th>
            //               </tr>
            //           </thead>
            //           <tbody>
            //               <tr>
            //                 <td class="qmod-control"></td>
            //                 <td class="qmod-textl">{tplLang.currentestimate | toLang tLang}</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.qr2.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.currentEst.fr2.content | asQHLast }</td>
            //               </tr>
            //               <tr>
            //                 <td class="qmod-control"></td>
            //                 <td class="qmod-textl">{tplLang.sevendaysago | toLang tLang}</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.qr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.qr2.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.fr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days7Ago.fr2.content | asQHLast }</td>
            //               </tr>
            //               <tr>
            //                 <td class="qmod-control"></td>
            //                 <td class="qmod-textl">{tplLang.thirtydaysago | toLang tLang}</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.qr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.qr2.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.fr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days30Ago.fr2.content | asQHLast }</td>
            //               </tr>
            //               <tr>
            //                 <td class="qmod-control"></td>
            //                 <td class="qmod-textl">{tplLang.sixtydaysago | toLang tLang}</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.qr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.qr2.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.fr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days60Ago.fr2.content | asQHLast }</td>
            //               </tr>
            //               <tr>
            //                 <td class="qmod-control"></td>
            //                 <td class="qmod-textl">{tplLang.ninetydaysago | toLang tLang}</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.qr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.qr2.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.fr1.content | asQHLast }</td>
            //                 <td class="qmod-textr">{shareInfo.earningsEstimates.consensusEpsEstimatesTrends.days90Ago.fr2.content | asQHLast }</td>
            //               </tr>
            //           </tbody>
            //         </table>
            //       </div>
            //   </div>
            //   <div class="qmod-tool-wrap" rv-unless="data.datatype | = 'equity'">
            //       <div class="qmod-block-wrapper">
            //         <div class="qmod-invalid" rv-if="data | isValidSymbol">{tplLang.nodata| toLang tLang}: {data.symbolstring}</div>
            //         <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol | toLang tLang}: {data.symbolstring}</div>
            //       </div>
            //   </div>
            // </div>`
          );
        tmplEle.appendChild(txt);
        this.$nextTick(()=> {
          this.$refs.fullEarnings.appendChild(tmplEle);

          this.$refs.fullEarnings.addEventListener('qmod-after-template-bind',  () => {
              console.debug('consensus eps estimates chart template bound**************')

              if( !this.chartInitialized ){
                this.chartInitialized = true;

                setTimeout(() => { 
                  window.dispatchEvent(new Event('resize'));
                });
              }
          },false) //shoudl ensure it is fired only once  {once:true}
        });

        this.justMounted = true;

    },
    activated() {
        console.debug(this.$options.name + " activated");
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
      return {
          chartInitialized: false,
          justMounted: false,
          displaySnapshot: false,
          snapshotTarget: null,
          moreMenuItems: [
              {
                label: 'Take Snapshot',

                icon: 'pi pi-camera',
                command: () => {
                  this.openSnapshotDialog();
                }
              },    
            ],
      }
    },
    
    methods: {
        
      toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementById('fullEarnings');
            this.displaySnapshot = true;
        },
       
        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible ) {
              window.dispatchEvent(new Event('resize'));
            }
        },
    },
   
   
}
</script>

<style scoped>
*:not(.pi), ::v-deep(*:not(.pi)) {
  font-family: "Trebuchet MS", Verdana;
}

.more-options {
    position: absolute;
    right: 0.75vw;
}

::v-deep(.qmod-earnings .qmod-tool-wrap) {
    margin: 0px;
}



::v-deep(h3.qmod-heading) {
  font-size: 24px;
  color: #32364e;
  font-weight: bold;
  padding: 0px 0px 12px 0px;
}

::v-deep(.qmod-earnings .qmod-chart-js > div) {
  max-width: 100%;
}

::v-deep(.highcharts-container) {
  height: 300px !important;
  width: 100% !important;
}

::v-deep( .highcharts-root) {
  height: 300px !important;
  width: 100% !important;
}

::v-deep(.highcharts-background) {
  height: 300px;
  width: 100%;
}

::v-deep(.highcharts-plot-background) {
  height: 300px;
  width: 100% !important;
}

::v-deep(.highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line) {
  stroke-dasharray: 1, 3;
}

::v-deep(.highcharts-axis-labels > text) {
  color: #7f7f7f !important;
  fill: #7F7F7F !important;
  font-size: 10px !important;
}

::v-deep(.highcharts-legend .highcharts-legend-box) {
  stroke: none;
}
::v-deep(.highcharts-legend-item.highcharts-column-series text) {
  color: #7F7F7F !important;
  fill: #7F7F7F !important;
}
/* #highcharts-1bklqjv-7 > svg > g.highcharts-series-group > g.highcharts-series.highcharts-series-2.highcharts-column-series.highcharts-tracker > path:nth-child(4) */
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-0 rect.highcharts-point),
::v-deep(.highcharts-series.highcharts-series-0.highcharts-column-series.highcharts-tracker path),
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-0.highcharts-series-0 .highcharts-point) {
  fill: rgb(51, 204, 153);
  stroke: rgb(51, 204, 153);
}
::v-deep(.highcharts-point.highcharts-color-0 ) {
  fill: rgb(51, 204, 153);
  stroke: rgb(51, 204, 153);
  opacity: 0.85;
}

::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-1 rect.highcharts-point),
::v-deep(.highcharts-series.highcharts-series-1.highcharts-column-series.highcharts-tracker path),
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-1.highcharts-series-1 .highcharts-point) {
  stroke: #E63E3E;
  fill: #E63E3E;
}
::v-deep(.highcharts-point.highcharts-color-1) {
  stroke: #E63E3E;
  fill: #E63E3E;
  opacity: 0.85;
}

::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-2 rect.highcharts-point),
::v-deep(.highcharts-series.highcharts-series-2.highcharts-column-series.highcharts-tracker path),
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-2.highcharts-series-2 .highcharts-point) {
  stroke: #693BF5;
  fill: #693BF5;
}
::v-deep(.highcharts-point.highcharts-color-2) {
  stroke: #693BF5;
  fill: #693BF5;
  opacity: 0.85;
}
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-3 rect.highcharts-point),
::v-deep(.highcharts-series.highcharts-series-2.highcharts-column-series.highcharts-tracker path),
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-3.highcharts-series-3 .highcharts-point path) {
  stroke: #FFBD53;
  fill: #FFBD53;
}
::v-deep(.highcharts-point.highcharts-color-3) {
  stroke: #FFBD53;
  fill: #FFBD53;
  opacity: 0.85;
}

::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-4 rect.highcharts-point),
::v-deep(.highcharts-series.highcharts-series-2.highcharts-column-series.highcharts-tracker path),
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-color-4.highcharts-series-4 .highcharts-point path) {
  fill: #5367FF;
  stroke: #5367FF;
}
::v-deep(.highcharts-point.highcharts-color-4) {
  fill: #5367FF;
  stroke: #5367FF;
  opacity: 0.85;
}

::v-deep(.highcharts-label.highcharts-tooltip tspan:nth-child(2)) {
  fill: #33CC99 !important;
}
::v-deep(.highcharts-label.highcharts-tooltip tspan:nth-child(5)) {
  fill: #E63E3E !important;
}
::v-deep(.highcharts-label.highcharts-tooltip tspan:nth-child(8)) {
  fill: #693BF5 !important;
}
::v-deep(.highcharts-label.highcharts-tooltip tspan:nth-child(11)) {
  fill: #FFBD53 !important;
}
::v-deep(.highcharts-label.highcharts-tooltip tspan:nth-child(14)) {
  fill: #5367FF !important;
}

::v-deep(.qmod-earnings .dataTables_wrapper) {
  margin: 36px 0px 0px;
}
</style>