<template>
    
    <ScrollPanel class='corporate-events-scrollpanel'>
        <div
            id='corporate-events' 
            data-qmod-tool="corporateevents" 
            :data-qmod-params="corporateEventsParams" 
            class="corporate-events-qtool">
        </div>
    </ScrollPanel>    
</template>

<script>
import ScrollPanel from 'primevue/scrollpanel';

export default {
    name: 'CorporateEvents',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    computed: {
        corporateEventsParams() {
            return '{"symbol":"' + this.selectedSymbol + '", "resultsPerPage":100}';
        }
    },

    components: {
        ScrollPanel
    },

    data() {
        return {
            initialized: false      
        }
    },

    mounted() {
        console.log(this.$options.name+" mounted for " + this.selectedSymbol);
        
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode(
            `<div class="qmod-ui-tool qmod-corpevents">
                <div class="qmod-block-wrapper" rv-if="data.datatype | = 'equity'">
                    <div class="qmod-corp-event-wrap">
                        <table class="qmod-table qmod-corpevents-table" width="100%">
                            <thead>
                                <tr>
                                <th class="qmod-textl">{tplLang.datetime| toLang tLang}</th>
                                <th class="qmod-textl">{tplLang.eventdetails| toLang tLang}</th>
                                <th class="qmod-textl">{tplLang.type| toLang tLang}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr rv-each-event="corpEvents">
                                { report.reportDate }
                                <td class="qmod-textl">{event.datez}</td>
                                <td class="qmod-textl" rv-html="event.text"></td>
                                <td class="qmod-textl">{event.type}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                <div class="qmod-block-wrapper qmod-text" rv-unless="data.datatype | = 'equity'">
                <div class="qmod-block-wrapper">
                    <div class="qmod-invalid" rv-unless="data | isValidSymbol">{tplLang.invalidsymbol| toLang tLang}: {data.symbolstring}</div>
                    <div class="qmod-invalid" rv-if="data | isValidSymbol">{tplLang.nodata| toLang tLang}: {data.symbolstring}</div>
                </div>
            </div>
        </div>`
        );

        tmplEle.appendChild(txt);
        document.getElementById('corporate-events').appendChild(tmplEle);

        document.getElementById('corporate-events').addEventListener('qmod-template-after-bind', () => {
            console.debug('corporate events template bound**************')

            this.initialized = true;
        }, false);

        try {
            window.qMod.loadTool('corporate-events');
        }catch(err){
            console.log("err loading qmod ", err);
        }
    },

    activated() {
        console.debug(this.$options.name + " activated");
       
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
    },

}
</script>

<style scoped>
.corporate-events-scrollpanel {
	width: 100%;
	height: 100%;
}
.corporate-events-scrollpanel ::v-deep(.p-scrollpanel-content) {
	padding: 0;
    overflow: auto;
}
.corporate-events-scrollpanel ::v-deep(.p-scrollpanel-wrapper) {
	border-right: 9px solid #F2F2F2;
}
.corporate-events-scrollpanel ::v-deep(.p-scrollpanel-bar) {
	background-color: #BFBFBF;
	opacity: 1;
	transition: background-color .2s;
}
/* ::v-deep(.qmod-textl) {
    width: 100%;
} */
::v-deep(.qmod-ui-tool) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.qmod-corpevents .qmod-corp-event-wrap) {
    margin: 0;
}

::v-deep(.qmod-corpevents table.qmod-table.dataTable > thead > tr > th) {
    padding: 10px 18px;
}
::v-deep(.qmod-corpevents table.dataTable thead th) {
    color: #32364e;
}
::v-deep(.qmod-corpevents table.dataTable thead th), 
::v-deep(.qmod-corpevents table.dataTable thead td) {
    border-bottom: none;
}
::v-deep(.qmod-corpevents table.dataTable thead th.sorting:after),
::v-deep(.qmod-corpevents table.dataTable thead th.sorting_asc:after), 
::v-deep(.qmod-corpevents table.dataTable thead th.sorting_desc:after) {
    color: #32364e;
    top: 12px;
    font-size: 12px;
}
::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting:last-child:after),
::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting_asc:last-child:after), 
::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting_desc:last-child:after) {
    right: 4.5%;
}
::v-deep(.qmod-corpevents table.dataTable tbody tr td:first-child) {
    font-size: 12px;
    font-weight: bold;
    /* padding-left: 20px; */
    color: #7F7F7F;
}
::v-deep(.qmod-ui-tool a) {
    text-decoration: none;
    color: #5367FF;
}

::v-deep(.qmod-corpevents .dataTables_wrapper .dataTables_info),
::v-deep(.qmod-corpevents .dataTables_wrapper .dataTables_paginate) {
    display: none;
}

::v-deep(.qmod-invalid) {
    padding: 16px 0px 0px 0px;
}

@media (max-width: 760px) {
    #corporate-events {
        padding-right: 8px;
        /* padding-left: 8px */
    }
    /* ::v-deep(.p-scrollpanel-wrapper) {
        border-right: 0px !important;
    } */
}
</style>
