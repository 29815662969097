<template>
    <LoadingSpinner :showLoading="!chartInitialized"/>
    <div v-show='chartInitialized' style='position: relative;'>
        <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
		<Menu ref="menu" :model="moreMenuItems" :popup="true" />

        <div id = 'insiders-visuals' 
            data-qmod-tool="insiders"
            :data-qmod-params='insidersParams'
            class="insiders-visuals-qtool" ref="insiderVisuals"></div>
    </div>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />

    <AttributionFooter authors='quoteMedia' v-if='chartInitialized'/>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';

export default {
    name: 'InsidersVisuals',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            chartInitialized: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],
        }
    },

    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog,
        AttributionFooter
    },

    computed: {
        insidersParams() {
            return '{"symbol":"' + this.selectedSymbol + '","pagerLimit":25}';
        }
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = this.$refs.insiderVisuals.getElementsByClassName('qmod-block-wrapper qmod-section-insidersummary')[0].getElementsByClassName('pure-g')[0];
            this.displaySnapshot = true;
        }
    },

    mounted() {
        document.getElementById('insiders-visuals').addEventListener('qmod-after-template-bind',  () => {
            console.log('insiders visuals template bound**************')
            this.chartInitialized = true;

            // Adding templated code into the insiders visuals DOM after fully rendering
            // Summary Header Content
            let summaryHeaderEle = document.createElement('h2');
            summaryHeaderEle.setAttribute("class", "insiderssummary-header");
            let summaryHeaderTxt = document.createTextNode('Summary');
            summaryHeaderEle.appendChild(summaryHeaderTxt);

            // Transactions Header Content
            let transactionHeaderEle = document.createElement('h2');
            transactionHeaderEle.setAttribute("class", "insiderstransaction-header");
            let transactionHeaderTxt = document.createTextNode('Transactions');
            transactionHeaderEle.appendChild(transactionHeaderTxt);

            // Transactions section - Dropdown header labels
            let insiderDropdownLabelEle = document.createElement('span');
            insiderDropdownLabelEle.setAttribute("class", "qmod-dropdown-label");
            let insiderDropdownTxt = document.createTextNode('Insider Name:');
            insiderDropdownLabelEle.appendChild(insiderDropdownTxt);

            let transactionDropdownLabelEle = document.createElement('span');
            transactionDropdownLabelEle.setAttribute("class", "qmod-dropdown-label");
            let transactionDropdownTxt = document.createTextNode('Transaction Type:');
            transactionDropdownLabelEle.appendChild(transactionDropdownTxt);

            let yearDropdownLabelEle = document.createElement('span');
            yearDropdownLabelEle.setAttribute("class", "qmod-dropdown-label");
            let yearDropdownTxt = document.createTextNode('Transaction Year:');
            yearDropdownLabelEle.appendChild(yearDropdownTxt);

            // Adding Summary Header div before first child from div of class: qmod-block-wrapper qmod-section-insidersummary
            let summarySectionDiv = document.getElementsByClassName('qmod-block-wrapper qmod-section-insidersummary');
            if(summarySectionDiv[0]){
                summarySectionDiv[0].classList.add('card');
                let summaryTargetDiv = summarySectionDiv[0].childNodes;
                summaryTargetDiv[0].insertBefore(summaryHeaderEle, summaryTargetDiv[0].childNodes[0]);
            }

            // Adding Transactions Header div before first child from second child from div of class: qmod-block-wrapper qmod-section-insiders
            let transactionsSectionDiv = document.getElementsByClassName('qmod-block-wrapper qmod-section-insiders');
            if(transactionsSectionDiv[0]){
                transactionsSectionDiv[0].classList.add('card');
                let transactionsTargetDiv = transactionsSectionDiv[0].childNodes[1];
                transactionsTargetDiv.insertBefore(transactionHeaderEle, transactionsTargetDiv.childNodes[0]);
            }

            /* Adding all dropdown label spans before first child from each div of class: qmod-dropdown-container  
            from div of class: qmod-block-wrapper qmod-section-insiders */
            // if(transactionsSectionDiv[0]){
            //     let insiderDropdownTargetDiv = transactionsSectionDiv[0].childNodes[1].childNodes[0].childNodes[1].childNodes;
            //     insiderDropdownTargetDiv[0].insertBefore(insiderDropdownLabelEle, insiderDropdownTargetDiv[0].childNodes[0]);
                
            //     let transactionsDropdownTargetDiv = transactionsSectionDiv[0].childNodes[1].childNodes[0].childNodes[2].childNodes;
            //     transactionsDropdownTargetDiv[0].insertBefore(transactionDropdownLabelEle, transactionsDropdownTargetDiv[0].childNodes[0]);
                
            //     let yearDropdownTargetDiv = transactionsSectionDiv[0].childNodes[1].childNodes[0].childNodes[3].childNodes;
            //     yearDropdownTargetDiv [0].insertBefore(yearDropdownLabelEle, yearDropdownTargetDiv [0].childNodes[0]);
            // }

        }, false) // {once:true}

       try {

        window.qMod.loadTool('insiders-visuals'); 
       }catch(err ) {
            console.warn("error loading insider qmod", err);
       }
    },
    activated() {
       
       
    },
    deactivated() {
       
        
        this.displaySnapshot = false;
    
    },

}
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 16px;
    top: 16px;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-quotehead) {
    display: none;
}

::v-deep(.qmod-modifiers) {
    padding: 0px 16px;
}

::v-deep(.qmod-pagin-bottom) {
    margin-top: 0px;
}

/* Section Headers styling */
::v-deep(h2.qmod-heading.qmod-hbg.qmod-section-insidersummary),
::v-deep(h2.qmod-heading.qmod-hbg.qmod-section-insiders) {
    display: none;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] h2.qmod-heading.qmod-hbg.qmod-section-insiders),
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] h2.qmod-heading.qmod-hbg.qmod-section-insidersummary) {
    background-color: transparent;
}

/* Insider summary section styling */
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders div.qmod-section-insidersummary) {
    margin-bottom: 16px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-section-insidersummary .insiderssummary-header) {
    font-size: 26px;
    font-weight: 600;
    color: #32364e;
    padding: 0px;
    margin-bottom: 16px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-section-insidersummary .qmod-panel) {
    padding: 0px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column) {
    padding: 0px 16px 0px 0px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel [class*=pure-u]:last-child .qmod-panel-column) {
    padding-right: 0px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell .qmod-shares-traded) {
    background-color: #32364e;
    border-radius: 20px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-heading) {
    color: #7F7F7F;
    padding: 0px 0px 16px 0px;
    font-weight: normal;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-label) {
    padding: 0px 0px 4px 0px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-label .qmod-panel-subcell) {
    font-weight: normal;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-label:nth-child(4)) {
    padding-top: 16px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-graphic) {
    border-radius: 20px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-graphic:nth-child(3)) {
    background-color: #f8f9fa;
}
/* Pill that lays underneath the selling/buying bars styling */
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell-graphic:nth-child(5)) {
    display: flex;
    background-color: #33cc99; 
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell .qmod-buy-trades) {
    display: none;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-panel .qmod-panel-column .qmod-panel-cell .qmod-sell-trades) {
    background-color: #e63e3e;
    border-radius: 20px;
    opacity: 1;
    margin-left: auto;
}

/* Insiders section styling */
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders div.qmod-section-insiders) {
    padding: 16px 0px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders div.qmod-section-insiders .insiderstransaction-header) {
    font-size: 26px;
    font-weight: 600;
    color: #32364e;
    padding: 0px;
    margin-right: auto;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-section-insiders .pure-g) {
    justify-content: flex-end;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-section-insiders .pure-g .qmod-dropdown-container) {
    width: auto; 
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-dropdown-label) {
    color: #7F7F7F;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-dropdown-label:last-of-type) {
    display: none;
}
/* Data table styling */
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.dataTable tr) {
    border-top: 1px solid #ddd;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.dataTable.dtr-column > tbody > tr > td.control) {
    cursor: auto;   
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.dataTable.dtr-column > tbody > tr > td.control:before) {
    color: #FFFFFF;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th.qmod-textl),
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th) {
    color: #999;
    font-weight: 500;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th.qmod-textr),
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap tbody td.qmod-textr) {
    text-align: left;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap tbody tr td.qmod-insider-type .qmod-ch-up) {
    color: #33CC99;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap tbody tr td.qmod-insider-type .qmod-ch-down) {
    color: #e63e3e;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th.qmod-insider-amountowned) {
    padding-right: 40px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th.qmod-insider-amountowned:after) {
    right: 20%;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap thead tr th.qmod-insider-date),
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders table.qmod-table.qmod-insiders-table.nowrap tbody td.qmod-insider-date) {
    padding: 10px 0 10px 35px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-dropdown_toggle) {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border-radius: 20px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-filerOptions-dropdown) {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border-radius: 20px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-typeOptions-dropdown) {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border-radius: 20px;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .qmod-yearOptions-dropdown) {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border-radius: 20px;
}

::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] a.qmod-dropdown_toggle) {
    color: #222;
    background-color: #fff;
    border: 2px solid #32364e;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-dropdown-menu li > a:hover) {
    text-decoration: none;
    background-color: #32364e;
    color: #fff;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-dropdown-menu li > a.qmod-active) {
    background-color: #32364e;
    color: #fff;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .dataTables_wrapper .dataTables_paginate .paginate_button.current) {
    background: linear-gradient(#32364e, #32364e) !important;
    color: #fff !important;
    border: 1px solid #32364e;
    box-shadow: none;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .dataTables_wrapper .dataTables_paginate .paginate_button) {
    background-color: #fff;
    color: #32364e !important;
    border: 1px solid #32364e;
    font: 12px Trebuchet MS, Verdana !important;
    border-radius: 25px;
    font-family: 'quotemedia' !important;
}
::v-deep(.insiders-visuals-qtool[data-qmod-tool="insiders"] .qmod-insiders .dataTables_wrapper .dataTables_paginate .paginate_button:hover) {
    background-color: #32364e !important;
    color: #fff !important;
    border: 1px solid #32364e;
}
::v-deep(.qmod-insiders .dataTables_wrapper .dataTables_paginate) {
    float: none;
    text-align: center;
    padding-top: 1em;
    display: block;
}

</style>