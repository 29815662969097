<template>
   
    <LoadingSpinner :showLoading="!chartInitialized" style="margin-top: -10px;width: 100%"/>
    <div v-show="chartInitialized || isSafariBrowser()" class="snapshotable">
        <div :class="isMobile() ? 'logo-minquote-container-mobile' : 'logo-minquote-container'">
            <SecurityLogo v-if="!isMobile()" :security="{'symbol': selectedSymbol, 'url': companyProfile ? companyProfile.website : null}" />
            <div class="row-items" v-else>
                <SecurityLogo :security="{'symbol': selectedSymbol, 'url': companyProfile ? companyProfile.website : null}" />
                <FollowButton :followTarget="selectedEquity ? selectedEquity : selectedFund"/>
            </div>
            <!-- <Avatar class='filler-logo' :label='selectedSymbol' shape='circle' v-show='!isCompanyLogoLoaded'/>
            <div class='logo-wrapper' v-show='isCompanyLogoLoaded'>
                <img id='company-logo' @load='onCompanyLogoLoaded' >
            </div> -->
            <MiniQuotesSimple :name="companyName" :change="change" :changepercent="changepercent" :lastCurrency="lastCurrency" :currencyType="currencyType" component="Security Overview Chart" />
            <div class='end-items'>
                <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
                <Menu ref="menu" :model="moreMenuItems" :popup="true" />
                <div style="display:flex;">
                    <i class="pi pi-window-maximize" @click="goFullScreen()" style="color:#3c9;margin-right:15px;" v-tooltip.top="'Full screen'"/>
                    <router-link :to="$route.path + '/charting'" class="advanced-txt">
                     <!-- <span>Full Chart&#10230;</span> -->
                     <i class="pi pi-chart-line" v-tooltip.top="'Go to Charting'"/>
                    </router-link>
                </div>
            </div>
        </div>
        <div id="security-overview-chart-qtool"
            data-qmod-tool="interactivechart"
            :data-qmod-params='securityOverviewChartParams'
            class="qtool" ref="qmodDiv"></div>
    </div>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
   
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import MiniQuotesSimple from './MiniQuotesSimple';
import SecurityLogo from '../avatar/SecurityLogo.vue';
// import Avatar from 'primevue/avatar';
import FollowButton from '../button/FollowButton.vue';

import DataService from '@/service/DataService';
import StringUtils from '../../utilities/StringUtils';
import SnapshotDialog from '../modal/SnapshotDialog.vue';
import BrowserUtils from '../../utilities/BrowserUtils';

export default {
    name: 'SecurityOverviewChart',
    props: {
        selectedFund: {
            type: Object,
            required: false,
        },
        selectedEquity: {
            type: Object,
            required: false,
        },
        selectedSymbol: {
            type: String,
            required: true,
        },
        companyProfile: {
            type: Object,
            default: null
        }
    },
    computed: {
        securityOverviewChartParams() {
          return '{"yAxisLabelOffset":-20,"copyright":false,"xAxisTickSpace":100,"xAxisShowLastLabel":false,"symbol":"'+this.selectedSymbol+'","dateRange":6,"volumeEnabled":true,"compareEnabled":false,"adjType":1,"chart":{"chartType":"0","colors":["#33CC99","#623F54","#623F54","#ff9000","#623F54","#623F54"]}}';
        },
        securityOverviewMinQuoteParams() {
          return '{"symbol":"'+this.selectedSymbol+'","autoUpdateEnabled":true,"autoUpdateTime":10000}';
        }
    },
   components: {
    //  Avatar,

     MiniQuotesSimple,
     LoadingSpinner,
     MoreOptionsMenu,
     SnapshotDialog,
     SecurityLogo,
     FollowButton
   },

   created() {
       
   },
  
   
    mounted() {
        this.justMounted = true;
         console.debug(this.$options.name + " mounted");
        this.isCompanyLogoLoaded = false;
        // let logo = document.getElementById('company-logo');
        // logo.src = this.loadCompanyLogoSrc();
        // this.$refs.qmodDiv.replaceChildren();
        
        // this.$refs.qmodDiv.addEventListener('qmod-after-template-bind',  () => {
        //     console.debug('security overview chart template bound**************')
        //     this.chartInitialized = true;

        //     this.fetchData(); // For fetching mini quotes data for specific symbol
        // }, false) // {once:true}
       
        // this.$nextTick(()=>{
        //     window.securityOverviewChartQMod.loadTools(); 
        // })
        
        console.log(this.$options.name+" mounted for " + this.selectedSymbol);
     
      
    },

    unmounted() {
         console.debug(this.$options.name + " umounted");
        // let logo = document.getElementById('company-logo');
        // if (logo) {
        //     logo.src = '';
        // }
        this.isCompanyLogoLoaded = false;
        this.stopPollingPriceChange();
        this.justMounted = false;
    },

    activated() {
        console.debug(this.$options.name + " activated");
        this.startPollingPriceChange();
         this.$refs.qmodDiv.replaceChildren();
       // if( !this.justMounted) {
            console.log("about to added event listner in acdtivated") 
            this.$refs.qmodDiv.addEventListener('qmod-after-template-bind',  () => {
                console.debug('security overview chart template bound**************')
                this.chartInitialized = true;

                this.fetchData(); // For fetching mini quotes data for specific symbol
            }, false) // {once:true}
        
            //old window.securityOverviewChartQMod.loadTools(); 
            //this.$nextTick(()=>{
                //console.log("this.$refs.qmodDiv=", this.$refs.qmodDiv);
                window.qMod.loadTool("security-overview-chart-qtool");
            //})
           
        //}
    },
    deactivated() {
         
        // let logo = document.getElementById('company-logo');
        // if (logo) {
        //     logo.src = '';
        // }
        this.isCompanyLogoLoaded = false;
        this.stopPollingPriceChange();
        console.debug(this.$options.name + " deactivated");
        this.displaySnapshot = false;
    },

    data() {
      return {
        justMounted: false,
          chartInitialized: false,
          isCompanyLogoLoaded: false,

          changepercent: 0.0,
          change: 0.0,
          companyName: '',
          lastCurrency: 0.0,
          currencyType: '',
          refetcher: null,
          displaySnapshot: false,
          snapshotTarget: null,
          moreMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],
      }
    },

    methods: {
        goFullScreen() {
            let fsButton = document.getElementsByClassName('qmod-fullscreen-btn');
            if( fsButton ){
                fsButton[0].click();
            }
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },
        isSafariBrowser() {
            return BrowserUtils.isSafariBrowser();
        },

        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementsByClassName('snapshotable')[0];
            this.displaySnapshot = true;
        },
        
        startPollingPriceChange() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
			this.refetcher = setInterval(()=> {
				this.fetchData();
			}, 5000);
		},
		stopPollingPriceChange() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
		},
        async fetchData() {
			await DataService.getSnapQuotes(this.selectedSymbol).then(resp => {
					
				let quoteData = resp.data.results.quote;
				if( quoteData) {
                    const q = quoteData[0];
                    if( q.key.symbol === this.selectedSymbol) {
                        this.change = q.pricedata.change;
                        this.changepercent = q.pricedata.changepercent;
                        this.companyName = StringUtils.stripQMCompanyName(q.equityinfo.longname);
                        this.lastCurrency = q.pricedata.last;
                        this.currencyType = (q.key.symbol.includes(':CA') || q.key.symbol.includes(':AQL'))? 'CAD' : 'USD'
                    }
					
				}
                this.chartInitialized = true;
			});
		},

        loadCompanyLogoSrc() {
            let logo = document.getElementById('company-logo');
            if (logo) {
                logo.src = '';
            }
            this.isCompanyLogoLoaded = false;

            if (this.companyProfile) {
                return '//logo.clearbit.com/' + this.companyProfile.website +'?size=80'
            }
        },
        onCompanyLogoLoaded() {
            this.isCompanyLogoLoaded = true;
        }
    }
   
}
</script>

<style scoped>
.logo-minquote-container {
    display: flex;
    align-items: flex-start;
}   

.logo-minquote-container-mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

::v-deep(.follow-button) {
    height: 30px;
    margin-left: auto;
}  

.row-items {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}   

.end-items {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    align-items: flex-end;
    flex: 1 0 auto;
}

/* logo styling */
::v-deep(.logo-wrapper) {
    min-height: 35px;
}
/* .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.logo-wrapper img {
    border-radius: 16px;
}

.filler-logo.p-avatar {
    align-self: center;
    font-family: "Trebuchet MS", "Verdana";
    font-weight: 600;
    background-color: #f8f9fa;
    width: 5rem;
    height: 5rem;
    margin-right: 8px;
} */

/* advanced chart link styling */
.advanced-txt {
    font-family: "Trebuchet MS", "Verdana";
    color: #33CC99;
}
.advanced-txt:hover {
    text-decoration: underline;
}

/* --- minquote styling --- */

::v-deep(.security-overview-quote .company-name) {
    font-size: 24px;
    font-weight: bold;
    color: #32364E;
    margin-bottom: 8px;
}
::v-deep(.security-overview-quote .qmod-quote .qmod-segment .qmod-last-currency) {
    font-size: 18px;
    color: #32364E;
    margin-right: 4px;
}
::v-deep(.security-overview-quote .qmod-quote .qmod-segment .qmod-currency) {
    font-size: 18px;
    color: #32364E;
    margin-right: 8px;
}

/* --- interactive chart styling --- */
::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", "Verdana" !important;
}
::v-deep(.qtool) {
    text-align: left;
}
::v-deep(.qmod-hbg) {
    background-color: #33CC99;
    color: #fff;
}

::v-deep(.qmod-chart-js){
    background-color: #fff;
}

::v-deep(.highcharts-subtitle) {
    display:none;
}

::v-deep(button.qmod-group-btn.qmod-btn.qmod-active){
    background-color: #33CC99;
    border:0px !important;
    outline:none !important;
}

/* chart legend styling */
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend) {
    margin-top: 16px;
    margin-bottom: 0px;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li.qmod-input),
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li.qmod-input) {
    background-color: #1f3a56;
    color: #2e4047;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li button),
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li button) {
    background-color: #fff;
    border: 1px solid #32364E;
    border-radius: 4px !important;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li),
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li) {
    margin-right: 5px;
    background: none;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li button),
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li button:hover) {
    background-color: #1f3a56;
    color: #fff;
    border-radius: 4px;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li button) {
    color: #32364e;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li.qmod-active button),
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li.qmod-active button) {
    background-color: #32364E;
    color: #fff;
    border-radius: 4px;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-dropdown) {
    margin-left: auto !important;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-dropdown_toggle) {
    min-width: 80px;
    border-radius: 20px;
    background: #fff;
    color: #222;
    border: 1px solid #32364e;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-dropdown-menu li > a:hover) {
    text-decoration: none;
    background-color: #32364e;
    color: #fff;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-dropdown-menu li > a.qmod-active) {
    background-color: #32364e;
    color: #fff;
}

::v-deep(ul.qmod-legend.qmod-legend-list),
::v-deep(h2.qmod-heading),
/* ::v-deep(.qmod-chart-btns), */
::v-deep(ul.qmod-legend.qmod-lnd-time),
::v-deep(.qmod-icon-toggle-fa),
::v-deep(text.highcharts-title) {
    display: none !important;
}

/* ::v-deep(h2.qmod-heading > button){
    display:flex !important;
} */

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-block-wrapper) {
    /* background-color: transparent; */
    margin-bottom: 0px;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-chart-js) {
    margin: 0px;
    padding-top: 0px;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart) {
    overflow: visible !important;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-grid path) {
    stroke-width: 1px;
    stroke:#BFBFBF;
    stroke-dasharray: 1,5;
}
::v-deep(.qmod-dropdown-sm.qmod-dropdown-menu li > a:hover) {
    background-color: #32364e;
    color: #fff;
}
::v-deep(.qmod-dropdown-menu li > a.qmod-active) {
    background-color: #32364e;
    color: #fff;
}
::v-deep(li.qmod-dd-more-item.qmod-list-header > span) {
    color: white;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .highcharts-container) {
    border: none;
    border-radius: 4px;
}
::v-deep(i.fa.fa-plus-circle) {
    color: white;
}

/* in-chart styling */
::v-deep(.highcharts-title) {
    display: none;
}

::v-deep( rect.highcharts-background){
    border:none;
    stroke-width:0;
}

::v-deep(.qmod-chips){
    display: none !important;
}

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-axis-labels) {
    fill: #7F7F7F;
    font-size: 10px;
}
::v-deep(text.highcharts-axis-title) {
    display: none;
}
::v-deep(path.highcharts-axis-line),
::v-deep(path.highcharts-tick) {
    stroke: none;
}
::v-deep(.highcharts-grid highcharts-xaxis-grid) {
    display: none;
}
::v-deep(.highcharts-axis-labels) {
    font-weight: bold;
    font-size: 12px;
    font-family: "Trebuchet MS", "Verdana" !important;
}
::v-deep(.highcharts-grid.highcharts-xaxis-grid) {
    display: none;
}
::v-deep(path.highcharts-area) {
    fill: #33CC99;
    opacity: 0.3;
}

::v-deep(path.highcharts-xaxis-line) {
    stroke: none;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels) {
    transform: translate(0, -4px);
}
::v-deep(.highcharts-axis-labels.highcharts-yaxis-labels) {
    transform: translate(40px, 0);
}
::v-deep(text.highcharts-axis-title) {
    transform: translate(-30px, 0);
}
::v-deep(text.highcharts-credits) {
    transform: translate(-60px, -20px);
}

/* chart tooltip styling */
::v-deep(.highcharts-tooltip) {
    opacity: 0.7 !important;
    font-family: "Trebuchet MS", "Verdana" !important;
    border-color: #32364E !important;
    color: #32364E !important;
    border-radius: 5px !important;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-tooltip .qmod-tt-title) {
    border-bottom: 1px solid #F2F2F2;
}
::v-deep(.highcharts-tooltip .qmod-tt-title .qmod-tt-title-date) {
    color: #32364E;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-tooltip > span table th) {
    color: #A6A6A6;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-tooltip > span table td) {
    color: black;
}
::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-tooltip .qmod-chart-symbol) {
    color: #32364E;
}

/* Volume area styling */
::v-deep(.highcharts-column-series > rect) {
    fill: #A6A6A6;
}

/* @media (max-width: 760px) {
    .logo-wrapper {
        height: 50px;
        width: 50px;
    }
    #company-logo {
        height: 50px;
        width: 50px;
    }
} */
</style>