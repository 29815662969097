<template>


  <div>
  <LoadingSpinner :showLoading="!chartInitialized" minHeight="100px;"/>  
  <div v-show="chartInitialized" style="position: relative;">
    <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
    <Menu ref="menu" :model="moreMenuItems" :popup="true" />
    <i class="pi pi-info-circle" v-tooltip.right="'The mean recommendation is scored on a scale from 1-5. 1 represents analyst consensus of &quot;Strong Buy&quot; and 5 represents analyst consensus of &quot;Strong Sell&quot;.'"></i>
    <div id="analystRecommends" ref="analystRecommends"
      data-qmod-tool="analystrecommendations"
      :data-qmod-params='analystParams'
      class="analyst-ratings-history-chart-qtool">
    </div>
    
  </div>
 <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
 </div>

</template>

<script>

import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'AnalystRecommendations',
    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
         selectedEquity: {
            type: Object,
            required: true,
        }

    },
    computed : {
        analystParams() {
         
          return '{"chart":{"colors":["#33CC99","#94E4C9","#FFF2CC","#F29C9C","#EA5C5C"],"legend":{"textColor":"#d1d4dc","backgroundColor":"#d1d4dc","textColorHover":"#000000","shadow":false},"yAxis":{"titleTextColor":"#d1d4dc","labelTextColor":"#d1d4dc"},"xAxis":{"titleTextColor":"#d1d4dc","labelTextColor":"#d1d4dc"}},"lang":"en","symbol":"'+this.selectedSymbol+'"}';
        }
    },
   components: {
     LoadingSpinner,
     MoreOptionsMenu,
     SnapshotDialog
   },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
          let txt = document.createTextNode(
            `<div class="qmod-ui-tool qmod-analyst-recommendations">
              
              <div class="qmod-block-wrapper qmod-analyst-rh" rv-if="shareInfo.analyst.strongBuy">
                  <h3 class="qmod-heading">{'recommendationshistory' | i8ln tLang}</h3>
                  <div class="qmod-chart-js">
                    <div class="rhhart"></div>
                  </div>
                  <table class="qmod-table qmod-table-hover qmod-rechistory-table nowrap" width="100%">
                    <thead>
                        <tr>
                          <th class="qmod-control"></th>
                          <th class="qmod-textl qmod-th-1">{'date' | i8ln tLang}</th>
                          <th class="qmod-textr">{'threemonthsago' | i8ln tLang}</th>
                          <th class="qmod-textr">{'twomonthsago' | i8ln tLang}</th>
                          <th class="qmod-textr">{'onemonthago' | i8ln tLang}</th>
                          <th class="qmod-textr">{'current' | i8ln tLang}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td class="qmod-control"></td>
                          <td class="qmod-textl">{'strongbuy' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongBuy.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongBuy.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongBuy.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongBuy.current}</td>
                        </tr>
                        <tr>
                          <td class="qmod-control"></td>
                          <td class="qmod-textl">{'moderatebuy' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateBuy.current}</td>
                        </tr>
                        <tr>
                          <td class="qmod-control"></td>
                          <td class="qmod-textl">{'recommendhold' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.hold.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.hold.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.hold.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.hold.current}</td>
                        </tr>
                        <tr>
                          <td class="qmod-control"></td>
                          <td class="qmod-textl">{'moderatesell' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateSell.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateSell.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateSell.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.moderateSell.current}</td>
                        </tr>
                        <tr>
                          <td class="qmod-control"></td>
                          <td class="qmod-textl">{'strongsell' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongSell.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongSell.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongSell.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.strongSell.current}</td>
                        </tr>
                        <tr>
                          <td class="qmod-control"></td>
                          <td>{'meanrec' | i8ln tLang}</td>
                          <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month3}</td>
                          <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month2}</td>
                          <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month1}</td>
                          <td class="qmod-textr">{shareInfo.analyst.meanRecommend.current}</td>
                        </tr>
                    </tbody>
                  </table>
                  <div class="qmod-label qmod-broker-desc">{'analystnote' | i8ln tLang}</div>
              </div>
            </div>`
            // `<div class="qmod-ui-tool qmod-analyst-recommendations">
            //   <h3 class="qmod-heading">Recommendations History</h3>
            //   <div class="qmod-invalid" rv-if="shareInfo.empty">{tplLang.nodata| toLang tLang}: {shareInfo.analyst.symbolstring}</div>
            //   <div class="qmod-block-wrapper qmod-analyst-rh" rv-if="shareInfo.analyst.strongBuy">
            //     <div class="qmod-chart-js">
            //       <div class="rhhart"></div>
            //     </div>
            //     <table class="qmod-table qmod-table-hover qmod-rechistory-table nowrap" width="100%">
            //       <thead>
            //           <tr>
            //             <th class="qmod-control"></th>
            //             <th class="qmod-textl qmod-th-1">{'date' | i8ln tLang}</th>
            //             <th class="qmod-textr">{'threemonthsago' | i8ln tLang}</th>
            //             <th class="qmod-textr">{'twomonthsago' | i8ln tLang}</th>
            //             <th class="qmod-textr">{'onemonthago' | i8ln tLang}</th>
            //             <th class="qmod-textr">{'current' | i8ln tLang}</th>
            //           </tr>
            //       </thead>
            //       <tbody>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td class="qmod-textl">{'strongbuy' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongBuy.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongBuy.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongBuy.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongBuy.current}</td>
            //           </tr>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td class="qmod-textl">{'moderatebuy' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateBuy.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateBuy.current}</td>
            //           </tr>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td class="qmod-textl">{'recommendhold' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.hold.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.hold.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.hold.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.hold.current}</td>
            //           </tr>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td class="qmod-textl">{'moderatesell' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateSell.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateSell.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateSell.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.moderateSell.current}</td>
            //           </tr>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td class="qmod-textl">{'strongsell' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongSell.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongSell.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongSell.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.strongSell.current}</td>
            //           </tr>
            //           <tr>
            //             <td class="qmod-control"></td>
            //             <td>{'meanrec' | i8ln tLang}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month3}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month2}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.meanRecommend.month1}</td>
            //             <td class="qmod-textr">{shareInfo.analyst.meanRecommend.current}</td>
            //           </tr>
            //       </tbody>
            //     </table>
            //   </div>
            // </div>`
          );
        tmplEle.appendChild(txt);
        this.$nextTick(()=> {
          this.$refs.analystRecommends.appendChild(tmplEle);

          this.$refs.analystRecommends.addEventListener('qmod-after-template-bind',  () => {
              console.debug('analyst ratings history chart template bound**************')
              
              if( !this.chartInitialized){
                this.chartInitialized = true;
                
                setTimeout(() => { 
                  window.dispatchEvent(new Event('resize'));
                }); 
              }
              
          },false) //shoudl ensure it is fired only once  {once:true}
        });
        
        
      
        this.justMounted = true;

    },
    activated() {
        console.debug(this.$options.name + " activated");
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
      return {
          chartInitialized: false,
          justMounted: false,
          displaySnapshot: false,
          snapshotTarget: null,
          moreMenuItems: [
              {
                label: 'Take Snapshot',

                icon: 'pi pi-camera',
                command: () => {
                  this.openSnapshotDialog();
                }
              },    
            ],
      }
    },
    
    methods: {
        
      toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementById('analystRecommends');
            this.displaySnapshot = true;
        },

        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible ) {
              window.dispatchEvent(new Event('resize'));
            }
        },
       
    },
   
   
}
</script>

<style scoped>
*:not(.pi), ::v-deep(*:not(.pi)) {
  font-family: "Trebuchet MS", Verdana !important;
}

.more-options {
    position: absolute;
    right: 0.75vw;
    z-index: 9999;
}

::v-deep(.pi-info-circle) {
  position: absolute;
  left: 300px;
  top: 10px;
  z-index:9999;
}

::v-deep(.qmod-block-wrapper) {
    margin: 0px;
    background: none;
}

::v-deep(.qmod-analyst-recommendations .qmod-chart-js) {
  top: -50px;
}

::v-deep(.qmod-analyst-recommendations .qmod-chart-js > div) {
  max-width: 100%;
}

::v-deep(h3.qmod-heading) {
  font-size: 24px;
  color: #32364e;
  font-weight: bold;
  padding: 0px 0px 12px 0px;
}

::v-deep(.highcharts-container) {
  height: 300px !important;
  width: 100% !important;
  overflow: visible !important;
}

::v-deep(.highcharts-root) {
  width: 100% !important;
  overflow: visible;
}

::v-deep(.highcharts-background) {
  width: 100%;
  fill: none;
}

::v-deep(.highcharts-plot-background) {
  width: 100%;
}

::v-deep(.highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line) {
  stroke-dasharray: 1, 3;
}

::v-deep(.highcharts-axis.highcharts-yaxis > text ) {
  color: #32364e !important;
  fill: #32364e !important;
}

::v-deep(.highcharts-axis-labels > text) {
  color: #7f7f7f !important;
  fill: #7F7F7F !important;
  font-size: 10px !important;
}

::v-deep(.highcharts-legend) {
  transform: translate(25%, calc(100% + 10px));

}

::v-deep(.highcharts-legend-box) {
  fill: none !important;
  stroke: none !important;
}

::v-deep(.highcharts-legend-item > text:first-of-type) {
  fill: #7F7F7F !important;
  color: #7F7F7F !important;
}

::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-series-0 .highcharts-point) {
    stroke: #33CC99;
    fill: #33CC99;
}
::v-deep(.highcharts-series.highcharts-series-0 rect) {
    stroke: #33CC99;
    fill: #33CC99;
    opacity: 0.85;
}
::v-deep(.highcharts-series.highcharts-series-0 path) {
    stroke: #33CC99;
    fill: #33CC99;
    opacity: 0.85;
}

::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-series-1 .highcharts-point) {
    stroke: #94E4C9;
    fill: #94E4C9;
}
::v-deep(.highcharts-series.highcharts-series-1 rect) {
    stroke: #94E4C9;
    fill: #94E4C9;
    opacity: 0.85;
}
::v-deep(.highcharts-series.highcharts-series-1 path) {
    stroke: #94E4C9;
    fill: #94E4C9;
    opacity: 0.85;
}
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-series-2 .highcharts-point) {
    stroke: #FFF2CC;
    fill: #FFF2CC;
}
::v-deep(.highcharts-series.highcharts-series-2 rect) {
    stroke: #FFF2CC;
    fill: #FFF2CC;
    opacity: 0.85;
}
::v-deep(.highcharts-series.highcharts-series-2 path) {
    stroke: #FFF2CC;
    fill: #FFF2CC;
    opacity: 0.85;
}
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-series-3 .highcharts-point) {
    stroke: #F29C9C;
    fill: #F29C9C;
}
::v-deep(.highcharts-series.highcharts-series-3 rect) {
    stroke: #F29C9C;
    fill: #F29C9C;
    opacity: 0.85;
}
::v-deep(.highcharts-series.highcharts-series-3 path) {
    stroke: #F29C9C;
    fill: #F29C9C;
    opacity: 0.85;
}
::v-deep(.highcharts-legend-item.highcharts-column-series.highcharts-series-4 .highcharts-point) {
    stroke: #EA5C5C;
    fill: #EA5C5C;
}
::v-deep(.highcharts-series.highcharts-series-4 rect) {
    stroke: #EA5C5C;
    fill: #EA5C5C;
    opacity: 0.85;
}
::v-deep(.highcharts-series.highcharts-series-4 path) {
    stroke: #EA5C5C;
    fill: #EA5C5C;
    opacity: 0.85;
}
::v-deep(.highcharts-legend-item.highcharts-series-5 path) {
  fill: #32364e;
  stroke: #32364e;
} 
::v-deep(.highcharts-series.highcharts-spline-series path:first-child) {
  stroke: #32364e;
}
::v-deep(.highcharts-markers.highcharts-spline-series.highcharts-tracker path) {
  fill: #32364e;
  stroke:#32364e;
} 

::v-deep(.qmod-analyst-recommendations .dataTables_wrapper) {
  margin: 36px 0px 0px;
}

</style>