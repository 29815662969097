<template>
    <PillMenuBar :navigationItems="menuItems" :autoScrolling="true" headerType="main" class="p-d-md-flex" v-if="!isMobile()" :enableCarousel="true">
        <template #custom>
            <Button class='subscribe-alert-button purple-button__secondary' icon='pi pi-bell' @click="onCreateNewAlertClick" v-if="$store.getters['featureSettings']?.premiumSubscriptionEnabled"/>
            <Button :class="{ 'toggle-notes-only-button': true, selected: toggleNotesButtonSelected }" v-tooltip.bottom="'Notes Only'" icon="pi pi-pencil" v-show="$route.path == '/equity/notes'" @click="toggleNotesOnly" />
            <FollowButton :followTarget="selectedEquity"/>
        </template>
    </PillMenuBar>

    <ScrollPanel style="width:100%;" v-if="usageAllowed">
        <div class="tab-div">
            <router-view v-slot="{ Component }">
                <keep-alive><component :is="Component"/></keep-alive>
            </router-view>

            <Overview v-show="$route.path == '/equity' && usageAllowed" />

            <Analysts v-show="$route.path == '/equity/analysts' && usageAllowed" />

            <FilingsView v-show="$route.path == '/equity/filings'" :hasFeatureEnabled="$store.getters['users/featureList'].includes('Filings')" @access-denied="goBackToOverview"/>

            <Insiders v-show="$route.path == '/equity/insiders' && usageAllowed" />

            <Dividends v-show="$route.path == '/equity/dividends' && usageAllowed" />
            
            <Trades v-show="$route.path == '/equity/trades'" :hasFeatureEnabled="$store.getters['users/featureList'].includes('Trades')" @access-denied="goBackToOverview"/>
            
            <Performance v-show="$route.path == '/equity/performance'" :hasFeatureEnabled="$store.getters['users/featureList'].includes('Performance')" @access-denied="goBackToOverview"/>
        </div>
    </ScrollPanel>

    <UsageLimitModal ref='usageLimitModal' :accessCheck="accessCheck"/>
    <UpgradeToPremiumModal ref='upgradeToPremiumModal' :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText='bodyText' />
    <InputAlertModal ref='createNewAlertModal' :inputData="{'symbol': selectedEquity.symbol, 'exchangeSymbol': selectedEquity.exchangeSymbol, 'assetTypeId': 1}" />
</template>

<script>
import Overview from '../components/equity/Overview';
import Insiders from '../components/equity/Insiders';
import Trades from '../components/equity/Trades';
import Performance from '../components/equity/Performance';
import Analysts from '../components/equity/Analysts.vue';
import Dividends from '../components/equity/Dividends.vue';
import FilingsView from '../components/equity/FilingsView.vue';
import PillMenuBar from '../components/menu/PillMenuBar';
import FollowButton from '../components/button/FollowButton.vue';
import InputAlertModal from '../components/QuoteMedia/alerts/modal/InputAlertModal.vue';
import UpgradeToPremiumModal from '../components/modal/UpgradeToPremiumModal.vue';
import UsageLimitModal  from '../components/modal/UsageLimitModal.vue';

import ScrollPanel from 'primevue/scrollpanel';
import ToggleButton from 'primevue/togglebutton';

import { mapState } from 'vuex';
import { PREMIUM_SUBSCRIPTION_TYPES} from '../common/constants';
import EventBus from '../event-bus';
import ResearchService from '../service/ResearchService';
import UserUtils from '../utilities/UserUtils';
import BrowserUtils from '../utilities/BrowserUtils';

import UserService from '../service/UserService';

export default {
    name: 'Equity',
    data() {
        return {
            // CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            bodyText: "To get full access to create alerts and the other premium features below, upgrade to premium.",

            justMounted: false,
            active: false,
            // menuItems: [
            //     { label: 'Overview', to: '/equity' },
            //     { label: 'Financials', to: '/equity/financials' },
            //     { label: 'Compare', to: '/equity/compare' },
            //     { label: 'Analysts', to: '/equity/analysts' },
            //     { label: 'Insiders', to: '/equity/insiders' },
            //     { label: 'Charting', to: '/equity/charting' },
            //     {label: 'Dividends', to: '/equity/dividends'},
            //     { label: 'Posts', to: '/equity/notes' },
            //     { label: 'Performance', to: '/equity/performance', className: 'purple-button__secondary'},
            //     {label: 'Trades', to: '/equity/trades', className: 'purple-button__secondary'},
            //     {label: 'Filings', to: '/equity/filings', className: 'purple-button__secondary'},
            // ],

            toggleNotesButtonSelected: false,

            userUtils: UserUtils,
            usageAllowed: false,
            accessCheck: null,
        };
    },
    components: {
        Overview,
        Insiders,
        Trades,
        FilingsView,
        Performance,
        PillMenuBar,
        ToggleButton,
        ScrollPanel,
        Analysts,
        Dividends,
        FollowButton,
        InputAlertModal,
        UpgradeToPremiumModal,
        UsageLimitModal
    },

    computed: {
        ...mapState(['selectedEquity']),

        menuItems() {
            if( !BrowserUtils.isMobile()){
                return  [
                    { label: 'Overview', to: '/equity' },
                    { label: 'Financials', to: '/equity/financials' },
                    { label: 'Compare', to: '/equity/compare' },
                    { label: 'Analysts', to: '/equity/analysts' },
                    { label: 'Insiders', to: '/equity/insiders' },
                    { label: 'Charting', to: '/equity/charting' },
                    {label: 'Dividends', to: '/equity/dividends'},
                    { label: 'Posts', to: '/equity/notes' },
                    {label: 'Trades', to: '/equity/trades', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled},
                    {label: 'Performance', to: '/equity/performance', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled},
                    {label: 'Filings', to: '/equity/filings', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled},
                ];
            }
            else {
                 return  [
                    { label: 'Overview', to: '/equity' },
                    
                    { label: 'Analysts', to: '/equity/analysts' },
                    
                    { label: 'Posts', to: '/equity/notes' },
                   
                ];
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        try {
            
            if(to.query.sym) {

                if( !to.query.assetType || to.query.assetType == 'other') {
                    ResearchService.setSelectedSymbolFromQM(to.query.sym, (resp)=> {
                        if (resp == 'success') {
                            //console.log('setSelectedSymbolFromQM completed ')
                            next();
                               
                            
                        }
                    });
                }
                else {
                    ResearchService.setSelectedEquity(to.query.exSym, to.query.sym, () => {
        
                       //console.log('setSelectedEquity completed ')
                        next();
                           
                        
                    });
                }
            }
            else{
                 
                        next();
                    
            }
        }
        catch( err) {
            console.log("error in equity before route enter:"+err)
        }
    },
    mounted() {
        this.justMounted = true;

        try {
            if (this.$route.fullPath === '/equity') {
                EventBus.emit('track-overview');
            }
        } catch (err) {
            return;
        }
    },
    activated() {
        this.active = true;
        this.usageAllowed = false;
        //console.debug('activated Equity');

        if( this.selectedEquity ){
            UserService.checkAnalysisToolsUsageRate("equities", this.selectedEquity.securityId).then( resp => {
                if( resp.data.status == 'error') {
                    this.accessCheck = resp.data.accessCheck;
                    this.$refs.usageLimitModal.open();
                    
                }
                else {
                    this.usageAllowed = true;
                    this.accessCheck = null;
                }
            })
        }

        this.justMounted = false;
    },
    deactivated() {
        //console.debug('deactivated Equity');
        this.justMounted = false;
        this.active = false;
    },

    watch: {
        $route() {
            try {
                EventBus.emit('track-equityNav', this.$route.fullPath)
            } catch (err) {
                return;
            }
        },

        selectedEquity(newVal, oldVal) {//eslint-disable-line
            if( newVal ) {
                this.usageAllowed = false;
                //console.log("selectedEquity changed to ", newVal)
                UserService.checkAnalysisToolsUsageRate("equities", this.selectedEquity.securityId).then( resp => {
                    if( resp.data.status == 'error') {
                         this.accessCheck = resp.data.accessCheck;
                         this.$refs.usageLimitModal.open();

                    }
                    else {
                        this.usageAllowed = true;
                        this.accessCheck = null;
                    }
                });
                    
            }
        }
    },

    methods: {

        

        isMobile() {
            return BrowserUtils.isMobile();
        },
        toggleNotesOnly() {
            this.toggleNotesButtonSelected = !this.toggleNotesButtonSelected;

            EventBus.emit('toggle-notes-only-button');
        },

        goBackToOverview() {
            this.$router.push({path: '/equity'});

            this.menuItems[8].selected = false;
            this.menuItems[9].selected = false;
            this.menuItems[10].selected = false;
            
            this.menuItems[0].selected = true;
        },

        onCreateNewAlertClick() {
            if (!this.$store.getters['users/featureList'].includes('Alerts') && !UserUtils.isPremiumSubscriber()) {
                this.$refs.upgradeToPremiumModal.open();
            } else {
                this.$refs.createNewAlertModal.open();
            }
        },
    },
};
</script>

<style scoped>
.tab-div {
    /* background: #1f2d40; */
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

::v-deep(.subscribe-alert-button) {
    border-radius: 50px;
}

::v-deep(.toggle-notes-only-button) {
    border-radius: 16px;
    padding: 6px 16px;
    border: 2px solid #32364e;
    background: #ffffff;
    color: #32364e;
}
::v-deep(.toggle-notes-only-button:enabled:hover),
::v-deep(.toggle-notes-only-button.selected) {
    background: #32364e;
    color: #ffffff;
    border: 2px solid #32364e;
}
::v-deep(.toggle-notes-only-button:focus) {
    border-color: #32364e;
    box-shadow: none;
}

::v-deep(.follow-button) {
    padding: 8px 16px;
    border-width: 2px;
    border-style: solid;
    border-radius: 28px;
}

.p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow-x: hidden;
}
@media (max-width: 760px) {
    .tab-div {
        margin-top: 8px;
    }
}
</style>
