<template>
    <div class='tab-content'>
        <keep-alive>
            <InsidersVisuals :selectedSymbol='selectedSymbol' key='insiderVisuals'/>
        </keep-alive>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';

import InsidersVisuals from '../QuoteMedia/InsidersVisuals';

export default {
    name: 'Insiders',
    data() {
        return {
            justMounted: false,
            active: false
        }
    },

    components: {
        InsidersVisuals
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
            let symbolToUse = SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
            if( symbolToUse.endsWith(":AQL") ) {
                symbolToUse = this.selectedEquity.proxySymbol.replace('/', '.');
            }
            return symbolToUse;
		},
    },
    watch: {
		selectedEquity() {
            console.log(this.$options.name + " selected equity watcher called");
            if ( !this.justMounted  ) {
                if( this.currentSymbol != this.selectedSymbol && this.$route.path =='/equity/insiders') {
                    console.log("about to call qmod loadtools");
                    this.currentSymbol = this.selectedSymbol;    
                    window.qMod.loadTool('insiders-visuals'); 
                }
                
			}
		
		},
         $route(newRoute, oldRoute) { //eslint-disable-line
            if( newRoute.fullPath =='/equity/insiders') {
                console.log("in insiders route watcher");
               if( !this.justMounted && this.active){
                    if( this.currentSymbol != this.selectedSymbol) {
                        this.currentSymbol = this.selectedSymbol;
                       window.qMod.loadTool('insiders-visuals'); 
                    }
                    else {
                        window.dispatchEvent(new Event('resize'))
                    }
                }
            }
        },
	},
    mounted() {
		console.log(this.$options.name + " mounted");
		this.justMounted = true;
        this.currentSymbol = this.selectedSymbol;
		//need to load news once this way as it was previously loaded from dashboard
        try{
            window.qMod.loadTool('insiders-visuals'); 
        }catch(err){
            console.warn("error loading insidersQMod qmod", err);
        }
	},
	activated() {
		this.active = true;
		console.log(this.$options.name + " activated");
		if( !this.justMounted){
            if( this.currentSymbol != this.selectedSymbol) {
                window.insidersQMod.loadTools(); 
            }
        }
		this.justMounted = false;
	},
	deactivated() {
        console.log(this.$options.name + " deactivated");
		this.justMounted = false;
		this.active = false;
	},
}
</script>

<style scoped>

</style>