<template>
    <div>
        <div class="tab-content" v-if='canAccessFeature'>
            <keep-alive>
                <TradesVisuals :selectedSymbol="selectedSymbol" v-observe-visibility="visibilityChanged" v-if="hasFeatureEnabled"/>
            </keep-alive>

        </div>

        <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="bodyText" @cancel-upgrade="cancelUpgrade"/>
    </div>
</template>

<script>
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';
import UserUtils from '../../utilities/UserUtils';

import TradesVisuals from '../QuoteMedia/TradesVisuals';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal';

export default {
    name: 'Trades',
    emits: ['access-denied'],
    props: {
        hasFeatureEnabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            justMounted: false,
            active: false,
            currentSymbol: null,
            bodyText: "To get full access to granular trade data for all securities and the other premium features below, upgrade to premium."
        };
    },

    components: {
        TradesVisuals, UpgradeToPremiumModal
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
            return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
        },

        canAccessFeature() {
            return this.hasFeatureEnabled && UserUtils.isPremiumSubscriber();
        }
    },

    watch: {
        selectedEquity() {
            console.log(this.$options.name + ' selected equity watcher called');
            if (!this.justMounted) {
                if (this.currentSymbol != this.selectedSymbol && this.$route.path == '/equity/trades') {
                    console.log('trades about to call qmod loadtools');
                    this.currentSymbol = this.selectedSymbol;
                    window.qMod.loadTool('tradesVisuals');
                }
            }
        },
        $route(newRoute, oldRoute) { //eslint-disable-line
            if (newRoute.fullPath == '/equity/trades') {
               if (this.canAccessFeature) {
                    if (this.currentSymbol != this.selectedSymbol) {
                       
                            this.currentSymbol = this.selectedSymbol;
                            window.qMod.loadTool('tradesVisuals');
                        
                    } else {
                        window.dispatchEvent(new Event('resize'));
                    }
                } else {
                    this.$refs.upgradeToPremiumModal.open();
                }
               
            }
        },
    },

    mounted() {
        console.log(this.$options.name + ' mounted');
        this.justMounted = true;

        if (this.canAccessFeature) {
            this.currentSymbol = this.selectedSymbol;

            window.qMod.loadTool('tradesVisuals');
        } 
    },

    activated() {
        this.active = true;
        console.log(this.$options.name + ' activated');
        if (!this.justMounted) {
            if (this.currentSymbol != this.selectedSymbol) {
                window.qMod.loadTool('tradesVisuals');
            }
        }
        this.justMounted = false;
    },

    deactivated() {
        console.log(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.active = false;
    },

    methods: {
        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible) {
                window.dispatchEvent(new Event('resize'));
            }
        },

        cancelUpgrade() {
            this.$emit('access-denied');
        }
    }
};
</script>