<template>


  <div>
  <LoadingSpinner :showLoading="!chartInitialized" minHeight="100px;"/>  
  <div v:show="chartInitialized" style="position: relative;">
    <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
      <Menu ref="menu" :model="moreMenuItems" :popup="true" />
    
    <div id="ar"
      data-qmod-tool="analystrecommendations"
      :data-qmod-params='analystParams'
      class="analyst-qtool">
    </div>
    
  </div>
 <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
 </div>

</template>

<script>

import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'AnalystRating',
    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
        showVolume: {
            type: Boolean,
            default: false,
        },
        showArea: {
          type: Boolean,
          default: true,
        }
    },
    computed : {
        analystParams() {
          let symbolToUse = this.selectedSymbol;
          
            
          return '{"chart":{"colors":["#00897b","#a6c04b","#ede348","#f18247","#df404a"],"legend":{"textColor":"#d1d4dc","backgroundColor":"#d1d4dc","textColorHover":"#000000","shadow":true},"yAxis":{"titleTextColor":"#d1d4dc","labelTextColor":"#d1d4dc"},"xAxis":{"titleTextColor":"#d1d4dc","labelTextColor":"#d1d4dc"}},"lang":"en","symbol":"'+symbolToUse+'"}';
        }
    },
   components: {
     LoadingSpinner,
     MoreOptionsMenu,
     SnapshotDialog
   },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
          let txt = document.createTextNode( 
            '<div class="qmod-ui-tool qmod-analyst-recommendations"> '
            + '<div class="qmod-block-wrapper qmod-analyst-ar" rv-if="shareInfo.empty"> '
            + '      <h3 class="qmod-heading qmod-hbg">Analyst Recommendations</h3> '
            + '      <div class="qmod-invalid" rv-if="shareInfo.empty">{tplLang.nodata| toLang tLang}: {shareInfo.analyst.symbolstring}</div> '
            + '  </div> '
            + '  <div class="qmod-block-wrapper qmod-analyst-ar" rv-if="shareInfo.analyst.strongBuy"> '
            + '      <h3 class="qmod-heading qmod-hbg">Analyst Ratings</h3> '
            + '      <div class="pure-g"> '
            + '        <div class="pure-u-1 pure-u-md-1-1"> '
            + '            <div class="qmod-chart-js"> '
            + '              <div class="arChart"></div> '
            + '            </div> '
            + '        </div> '
            + '      </div> '
            + '  </div> '
            + '</div>');
        tmplEle.appendChild(txt);
        document.getElementById('ar').appendChild(tmplEle);

        document.getElementById('ar').addEventListener('qmod-after-template-bind',  () => {
            console.debug('analyst template bound**************')
            this.chartInitialized = true;
            
        },false) //shoudl ensure it is fired only once  {once:true}

        try {
          window.qMod.loadTool('ar'); 
        }catch(err){
          console.log("err loading qmod ", err);
        }
        this.justMounted = true;

    },
    activated() {
        console.debug(this.$options.name + " activated");

        
        
       
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
      return {
          chartInitialized: false,
          justMounted: false,
          displaySnapshot: false,
          snapshotTarget: null,
          moreMenuItems: [
              {
                label: 'Take Snapshot',

                icon: 'pi pi-camera',
                command: () => {
                  this.openSnapshotDialog();
                }
              },    
            ],
      }
    },
    
    methods: {
        
      toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementsByClassName('analyst-qtool')[0];
            this.displaySnapshot = true;
        },
       
    },
   
   
}
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 0.75vw;
}
/* ::v-deep(.qmod-ui-tool) {
  font-size: 1.5vh;
} */

 /* ::v-deep(.qmod-chart-js) {
  background-color: #1f2d40;
}
::v-deep(.qmod-ui-tool .qmod-simplechart) {
  background-color: #1f2d40;
} */
::v-deep(.qmod-block-wrapper) {
  /* background-color: #1f2d40; */
  margin-bottom: 0px;
}

/* ::v-deep(.qmod-simplechart.qmod-ui-tool .highcharts-container) {
  border-color: #17212f;
} */
 ::v-deep(.qmod-heading.qmod-hbg){
  background-color: inherit;
  font-size: 24px;
  color: #32364E;
  font-family: "Trebuchet MS", Verdana;
  font-weight: 600;
  /* color: #d1d4dc !important; */
}

::v-deep(.qmod-analyst-recommendations .qmod-heading) {
  padding: 0px;
}

::v-deep(.qmod-analyst-recommendations .qmod-chart-js > div) {
  max-width: 100%;
  margin: 0px;
}
/*
::v-deep(.qmod-analyst-ratings .qmod-heading){
   color: #d1d4dc !important;
} */
::v-deep(a.qmod-dropdown_toggle.qmod-dropdown-sm) {
   /* background-color: #0D3349 !important; */
   /* background-color:#0a64a6 !important;
   color: #d1d4dc; */
    border-radius: 5px;
    padding: 4px 5px;
}
/* ::v-deep( .qmod-dropdown) {
  border-color: #17212f !important;
}
::v-deep( .qmod-dropdown_toggle .qmod-dropdown-sm .qmod-group) {
  background-color: #17212f !important;
  
}
::v-deep(.qmod-dropdown-menu .qmod-dropdown-sm) {
  background-color: #17212f;
  
  
}

::v-deep(.qmod-dropdown-menu li > a.qmod-active){
  background-color:rgb(100, 181, 246); 
  
}

 ::v-deep(.qmod-dropdown-menu li > a:hover) {
   background-color: rgba(13,51,73, 0.9) !important;
   
   color:#d1d4dc;
 }
 ::v-deep(.qmod-dropdown-sm.qmod-dropdown-menu li > a){
   background-color:#0D3349;
   
   color:#d1d4dc;
 }

::v-deep( .qmod-hbg ){ 
  background-color: #1f2d40;
 
}

::v-deep( .qmod-ui-tool .qmod-simplechart) {
  background-color: #1f2d40;
} */


::v-deep(.highcharts-container ) {
  overflow: visible !important;
  width: 100% !important;
  height: 100% !important;
}
::v-deep(.highcharts-root) {
  overflow: visible;
}
 ::v-deep(.qmod-analyst-rh) {
   display:none;
}



 ::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart) {
    width: 100%;
    height: 100%;
  }
/* 
 ::v-deep(.qmod-dropdown-menu.qmod-dropdown-menu-toggles li ) {
     background-color: #0D3349;
 }

::v-deep(.qmod-simplechart.qmod-ui-tool .qmod-main-chart .highcharts-tooltip) {
 
  background-color: #1f2d40;
} */


::v-deep(svg ){
  pointer-events: none;  /*removes anoying browser tooltip*/
  /* height: 20vh !important; */
}

/* ::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker )  {
  opacity: 0.8;
} */

::v-deep(.pure-u-1 .pure-u-md-1-2) {
  display:none;
}

::v-deep(rect.highcharts-point) {
  rx: 5;
    ry: 5;
    width: 14px;
    stroke: none;
        transform: translate(4px, 0px);
}

::v-deep(path.highcharts-point) {
  rx: 5;
    ry: 5;
    width: 14px;
    stroke: none;
        transform: translate(4px, 0px);
}


::v-deep(.highcharts-grid-line) {
  opacity: 0;
}

::v-deep(.highcharts-label.highcharts-data-label > text) {
  /* display:none; */
  color: black !important;
  font-weight:normal !important;
  /* color: #495057 !important;
  fill: #495057 !important;  */
  font-size: 14px !important;
}
::v-deep(.highcharts-data-labels.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker) {
  text-anchor: end;
  transform: translate(calc(100% - 0.75em), 0);
}
::v-deep(.highcharts-label.highcharts-data-label:nth-child(1)) {
  transform: translate(0, 3px);
}
::v-deep(g.highcharts-label.highcharts-data-label:nth-child(2)) {
  transform: translate(0, 31px);

}
::v-deep(g.highcharts-label.highcharts-data-label:nth-child(3)) {
 transform: translate(0, 60px);

}
::v-deep(g.highcharts-label.highcharts-data-label:nth-child(4)) {
  transform: translate(0, 91px);

}
::v-deep(g.highcharts-label.highcharts-data-label:nth-child(5)) {
  transform: translate(0, 122px);

}
::v-deep(.highcharts-label.highcharts-data-label > text > tspan) {
  stroke: none !important;
  stroke-width: 1px !important;
}

::v-deep(.highcharts-tooltip) {
  /* display: none; */
}

::v-deep(.highcharts-axis-labels.highcharts-yaxis-labels ) {
  display: none;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels > text) {
  font-size: 14px !important;
  color: #32364E !important;
  fill: #32364E !important;
  font-family: "Trebuchet MS", Verdana;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels > text tspan:nth-child(2)) {
  display: none;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels) {
  transform: translateX(-7.0em);
}

@media (max-width: 1000px) {
  ::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels) {
    transform: translateX(-6.0em) !important;
  }
}

::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels text) {
  text-anchor:start !important;
}

::v-deep(.highcharts-axis.highcharts-xaxis) {
  display:none !important;
}

::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect)  {
  transform: translateX(+5px);
}
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect:nth-child(1))  {
  fill:  #33CC99;
} 
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect:nth-child(2))  {
  fill:  #94E4C9;
}
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect:nth-child(3))  {
  fill:  #FFF2CC;
}
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect:nth-child(4))  {
  fill:  #F29C9C;
}
::v-deep(.highcharts-series-group > .highcharts-series.highcharts-series-0.highcharts-bar-series.highcharts-color-0.highcharts-tracker rect:nth-child(5))  {
  fill:  #EA5C5C;
}
</style>