<template>
    <div class='tab-content'>
        <keep-alive>
            <DividendsHistory :selectedSymbol='selectedSymbol' v-observe-visibility="visibilityChanged"/>
        </keep-alive>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';

import DividendsHistory from '../QuoteMedia/DividendsHistory.vue';

export default {
    name: 'Dividends',
    data() {
        return {
            justMounted: false,
            active: false,
            currentSymbol: null
        }
    },

    components: {
        DividendsHistory
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
					return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
			},
    },
    watch: {
		selectedEquity() {
            console.log(this.$options.name + " selected equity watcher called");
            if ( !this.justMounted  ) {
                if( this.currentSymbol !== this.selectedSymbol && this.$route.path === '/equity/dividends') {
                    console.log("about to call qmod loadtools");
                    this.currentSymbol = this.selectedSymbol;    
                    window.qMod.loadTool('dividends-history'); 
                }
                
			}
		
		},
         $route(newRoute, oldRoute) { //eslint-disable-line
            if( newRoute.fullPath =='/equity/dividends') {
                console.log("in dividends route watcher");
               if( !this.justMounted && this.active){
                    if( this.currentSymbol !== this.selectedSymbol) {
                        this.currentSymbol = this.selectedSymbol;
                         window.qMod.loadTool('dividends-history'); 
                    } else {
                        window.dispatchEvent(new Event('resize'))
                    }
                } 
            }
        },
	},
    mounted() {
		console.log(this.$options.name + " mounted");
		this.justMounted = true;
        this.currentSymbol = this.selectedSymbol;
		//need to load news once this way as it was previously loaded from dashboard
		try {
           window.qMod.loadTool('dividends-history'); 
		}catch(err){
        console.log("err loading qmod ", err);
      }
	},
	activated() {
		this.active = true;
		console.log(this.$options.name + " activated");
		if( !this.justMounted){
            if( this.currentSymbol !== this.selectedSymbol) {
                try {
                    window.qMod.loadTool('dividends-history'); 
                }catch(err){
                    console.log("err loading qmod ", err);
                }
            }
        }
		this.justMounted = false;
	},
	deactivated() {
        console.log(this.$options.name + " deactivated");
		this.justMounted = false;
		this.active = false;
	},

    methods: {
        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible) {
                window.dispatchEvent(new Event('resize'));
            }
        },
    }
}
</script>