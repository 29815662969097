<template>
    <div class="tab-content">
        <LoadingSpinner :showLoading="loading" style="width: 100%;height:100%"/>
        <div class="analysts-container" v-if='!loading'  v-observe-visibility="visibilityChanged">
            <div class="card">
                <keep-alive  :key="selectedSymbol">
                    <AnalystRecommendations :selectedEquity="selectedEquity" :selectedSymbol="selectedProxySymbol"/>
                </keep-alive>
            </div>
            <!-- <div class="card">
                <keep-alive>
                    <AnalystRatingsHistoryChart :selectedSymbol="selectedProxySymbol" />
                </keep-alive>
            </div> -->
           
                <keep-alive :key="selectedSymbol">
                    <FullEarnings :selectedEquity="selectedEquity" :selectedSymbol="selectedProxySymbol" />
                </keep-alive>
            
            <!-- <div class="card ceec-container">
                <keep-alive>
                    <ConsensusEPSEstimatesChart :selectedSymbol="selectedProxySymbol" />
                </keep-alive>
            </div> -->
        </div>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
//import AnalystRatingsHistoryChart from '../QuoteMedia/AnalystRatingsHistoryChart.vue';
// import TargetPriceHistoryChart from '../QuoteMedia/TargetPriceHistoryChart.vue';
// import ConsensusEPSEstimatesChart from '../QuoteMedia/ConsensusEPSEstimatesChart.vue';
// import CurrentTargetPriceChart from '../QuoteMedia/CurrentTargetPriceChart.vue';

import SecurityFormatter from '../../common/SecurityFormatter';

import { mapState } from 'vuex';
import AnalystRecommendations from '../QuoteMedia/AnalystRecommendations.vue';

import FullEarnings from '../QuoteMedia/FullEarnings.vue';

export default {
    name: 'Analysts',
    components: {
        LoadingSpinner,

        //AnalystRatingsHistoryChart,
        // TargetPriceHistoryChart,
        // ConsensusEPSEstimatesChart,
        // CurrentTargetPriceChart,
        AnalystRecommendations,
        FullEarnings
        

    },

    data() {
        return {
            loading: false,
            currentSymbol: null,
            justMounted: false,
            active: false
        }
    },

    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbol() {
			return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
            
		},
        selectedProxySymbol() {
            let sym = SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
            if( sym.endsWith(":AQL") ) {
                return this.selectedEquity.proxySymbol.replace("/",'.');//sym.substring(0, sym.indexOf(":AQL"));
            }
            else {
                return sym;
            }
        }
    },
    watch: {
		selectedEquity(newSec, oldSec) {
            console.log(this.$options.name + " selected equity watcher called");
            if( newSec && newSec != oldSec) {
                this.currentSymbol = null; // to trigger reload when route actually changes to analysts
            }
            // if ( !this.justMounted  ) {
                // if( this.currentSymbol !== this.selectedSymbol && this.$route.path === '/equity/analysts') {
                //     console.log("about to call qmod loadtools");
                //     this.currentSymbol = this.selectedSymbol;    
                //     this.loadTools();
                // }
                
			// }
		
		},
         $route(newRoute, oldRoute) { //eslint-disable-line
            if( newRoute.fullPath == '/equity/analysts') {
                console.log("in analysts route watcher");
              // if( !this.justMounted && this.active){
                    if( this.currentSymbol !== this.selectedSymbol) {
                        this.currentSymbol = this.selectedSymbol;
                        this.loadTools();
                    }
               // } 
            }
        },
	},
    mounted() {
		console.log(this.$options.name + " mounted");
		this.justMounted = true;
        //this.currentSymbol = this.selectedSymbol; 
	},
	activated() {
		this.active = true;
		console.log(this.$options.name + " activated");
		// if( !this.justMounted){
        //     if( this.currentSymbol !== this.selectedSymbol) {
        //         this.loadTools();
        //     }
        // }
		this.justMounted = false;
	},
	deactivated() {
        console.log(this.$options.name + " deactivated");
		this.justMounted = false;
		this.active = false;
	},

    methods: {
        loadTools() {
           
            window.qMod.loadTool('analystRecommends');
            window.qMod.loadTool('fullEarnings');
            // window.qMod.loadTool('targetPriceHistoryChart');
            // window.qMod.loadTool('consensusEPSEstimatesChart');
        },

        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible) {
                setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 1000);
            }
        },
    }
}
</script>

<style scoped>
.analysts-container {
    width: 100%;
}

.card {
    padding: 16px;
    margin-bottom: 16px;
}
.card:last-child {
    margin-bottom: 0px;
}

.earnings-container {
    padding: 16px 16px 0px;
}

</style>