<template>
  <div>
  <LoadingSpinner :showLoading="!chartInitialized" minHeight="100px;"/>  
  <div v-show="chartInitialized">
    <div id="dividends-history"
      data-qmod-tool="dividends"
      :data-qmod-params='dividendsHistoryParams'
      class="dividends-history-qtool">
    </div>
    
  </div>
    <AttributionFooter authors='quoteMedia' v-if='chartInitialized'/>
  </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import AttributionFooter from '../footer/AttributionFooter.vue';

export default {
    name: 'DividendsHistory',
    props: {
        selectedSymbol: {
            type: String,
            required: true,
        }
    },
   components: {
     LoadingSpinner, AttributionFooter
   },
    
    data() {
        return {
            chartInitialized: false,
        }   
    },
    computed : {
        dividendsHistoryParams() {
          return '{"lang":"en","limit":1500,"symbol":"' + this.selectedSymbol + '","resultsPerPage":10,"colVisible":"true,true,true,true,true,true,true","splitadjusted":false}'
        }
    },

    mounted() {
      let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode( 
            `<div class="qmod-ui-tool qmod-dividends">
                  <div class="qmod-block-wrapper qmod-dividends-output" rv-if="data.0.results.dividends.0.dividend">
                        <div class="pure-u-1-1 qmod-upcoming-dividends card">
                          <h3 class="qmod-heading qmod-subheading">{tplLang.upcomingdividends | toLang tLang}</h3>
                          <div class="qmod-panel qmod-dividends" rv-if="dividendsUpcoming.0" rv-class-qmod-loading="scope.binders.loadingState">
                              <div class="qmod-table-con qmod-dividends-upcoming-table-con">
                                <table class="qmod-table qmod-dividends-table qmod-dividends-upcoming-table nowrap" width="100%">
                                    <thead>
                                      <tr>
                                          <th class=" qmod-th-0"></th>
                                          <th class="qmod-textl qmod-th-1">{tplLang.divdate | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-2">{tplLang.amount | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-3">{tplLang.frequency | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-4">{tplLang.payment | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-5">{tplLang.record | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-6">{tplLang.announced | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-7">Type</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr class="qmod-table-row" rv-each-dividend="dividendsUpcoming">
                                          <td class=" qmod-td-0"></td>
                                          <td class="qmod-textl qmod-td-1">
                                            <div class="qmod-tcell">{dividend.date}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-2">
                                            <div class="qmod-tcell">{dividend.amount | asQHLast tLang '--' true} {dividend.currency}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-3">
                                            <div class="qmod-tcell">{dividend.frequency | asDividendFrequency}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-4">
                                            <div rv-if="dividend.payable">{dividend.payable}</div>
                                            <div rv-unless="dividend.payable">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-5">
                                            <div rv-if="dividend.record">{dividend.record}</div>
                                            <div rv-unless="dividend.record">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-6">
                                            <div rv-if="dividend.declared">{dividend.declared}</div>
                                            <div rv-unless="dividend.declared">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-7">
                                            <div class="qmod-tcell">{dividend.divtype | asDividendType}</div>
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                          </div>
                            <div class="qmod-panel qmod-dividends-no-data" rv-unless="dividendsUpcoming.0">{tplLang.nofuturedividends | toLang tLang} {scope.data.symbol} {tplLang.asof | toLang tLang} {scope.data.date | momentjs true 'MMM Do, YYYY' tLang}.
                              {tplLang.paymentofdividends | toLang tLang}
                          </div>
                        </div>
                        <div class="pure-u-1-1 qmod-dividends-history card">
                          <h3 class="qmod-heading qmod-subheading">{tplLang.dividendhistory | toLang tLang}</h3>
                          <div class="qmod-panel qmod-dividends" rv-if="dividendsHistory.0" rv-class-qmod-loading="scope.binders.loadingState">
                              <div class="qmod-table-con qmod-dividends-history-table-con">
                                <table class="qmod-table qmod-dividends-table qmod-dividends-history-table nowrap" width="100%">
                                    <thead>
                                      <tr>
                                          <th class=" qmod-th-0"></th>
                                          <th class="qmod-textl qmod-th-1">{tplLang.divdate | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-2">{tplLang.amount | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-3">{tplLang.frequency | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-4">{tplLang.payment | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-5">{tplLang.record | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-6">{tplLang.announced | toLang tLang}</th>
                                          <th class="qmod-textr qmod-th-7">Type</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr class="qmod-table-row" rv-each-dividend="dividendsHistory">
                                          <td class=" qmod-td-0"></td>
                                          <td class="qmod-textl qmod-td-1">
                                            <div class="qmod-tcell">{dividend.date}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-2">
                                            <div class="qmod-tcell" rv-unless="splitadjusted">{dividend.amount | asQHLast tLang '--' true} {dividend.currency}</div>
                                            <div class="qmod-tcell" rv-if="splitadjusted">{dividend.adjustedAmount | asQHLast tLang '--' true} {dividend.currency}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-3">
                                            <div class="qmod-tcell">{dividend.frequency | asDividendFrequency}</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-4">
                                            <div rv-if="dividend.payable">{dividend.payable}</div>
                                            <div rv-unless="dividend.payable">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-5">
                                            <div rv-if="dividend.record">{dividend.record}</div>
                                            <div rv-unless="dividend.record">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-6">
                                            <div rv-if="dividend.declared">{dividend.declared}</div>
                                            <div rv-unless="dividend.declared">--</div>
                                          </td>
                                          <td class="qmod-textr qmod-td-7">
                                            <div class="qmod-tcell">{dividend.divtype | asDividendType}</div>
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                          </div>
                          <div class="qmod-panel qmod-invalid qmod-dividends-no-data" rv-unless="dividendsHistory.0">{tplLang.nodata| toLang tLang} {scope.data.date | momentjs true 'MMM Do, YYYY' tLang}</div>
                        </div>
                  </div>
            </div>`);

        tmplEle.appendChild(txt);

        let dividendsHistoryDiv = document.getElementById('dividends-history');
        dividendsHistoryDiv.appendChild(tmplEle);
      
        dividendsHistoryDiv.addEventListener('qmod-after-template-bind',  () => {
            console.debug('dividends history template bound**************')
            this.chartInitialized = true;
        }, false) // {once:true}

      try {
        window.qMod.loadTool('dividends-history'); 
      }catch(err){
        console.log("err loading qmod ", err);
      }
    },
    activated() {
        console.debug(this.$options.name + " activated");

        
        
       
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
    },
   
   
}
</script>

<style>
.dividends-menu .p-submenu-header:first-child { 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
<style scoped>
.more-options {
    position: absolute;
    right: 16px;
    top: 16px;
}

::v-deep(.card) {
  padding: 16px 0px;;
}

::v-deep(.qmod-ui-tool *) {
  font-family: "Trebuchet MS", Verdana;
}

::v-deep(.qmod-heading) {
  padding: 0px 12px 16px;
}

::v-deep(.qmod-dividends .qmod-subheading) {
  margin: 0px;
}

::v-deep(h3.qmod-heading) {
  font-size: 24px;
  font-weight: bold;
  color: #32364e;
}

::v-deep(.qmod-block-wrapper) {
  margin: 0px;
  background: none;
}

::v-deep(.pure-g [class*="pure-u"] .qmod-dividends.qmod-panel) {
  padding: 5px 0px;
  
}

::v-deep(.qmod-dividends table.qmod-table.dataTable tr:last-child) {
  border-bottom: none;
}
::v-deep(.qmod-dividends table.dataTable.qmod-dividends-table.no-footer thead tr th) {
  text-align: left;
  color: #7F7F7F;
  font-weight: normal;
  padding-left:12px;
}
::v-deep(.qmod-dividends table.dataTable.qmod-dividends-table.no-footer th.qmod-dividend-type:after ) {
  right: 15%;
}
::v-deep(.qmod-dividends table.dataTable.qmod-dividends-table.no-footer tbody td) {
  text-align: left;
  color: black;
  padding-left:12px;
}

::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate) {
  text-align: center;
  float:none;
  padding: 0px;
  margin: 16px 0px 0px;
}

::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.previous),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.next),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.current),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button) {
    background: #FFF;
    color: #32364e !important;
    border: 1px solid #32364e;
    border-radius: 25px;
    font-size: 12px;
}

::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button.current),
::v-deep(.qmod-dividends .dataTables_wrapper .dataTables_paginate .paginate_button:hover) {
    background: linear-gradient(#32364e, #32364e);
    border: 1px solid #32364e;
    color: #FFF !important;
    font-size: 12px;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(td.control) {
  display:none;
}
::v-deep(th.control) {
  display:none;
}
</style>