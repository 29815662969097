<template>
   
  <div class="tab-content equity-overview-container">
    <LoadingSpinner :showLoading="!profileLoaded" style="width: 100%;height:100%"/>
    <div class="p-grid" v-if='profileLoaded'>
        <div class="left-col p-col-12 p-md-7" >
            <div class="card security-overview-chart-card no-bottom-padding " >
            
                <keep-alive>
                    <SecurityOverviewChart ref="chart" :selectedEquity="selectedEquity" :selectedSymbol="selectedSymbol" :companyProfile="equityOverview.companyProfile" :key="selectedSymbol"/>
                </keep-alive>
                
            </div>
            <CarouselMenuBar class="carousel-menu-bar" :menuItems="menu" v-if='isMobile()' />
            <template v-if='!isMobile()'>
                <div class="left-col card" v-if='profileLoaded'>
                    <div class='company-overview'>
                        <div class='p-grid'>
                            <div class="p-md-12">
                                <div class='grid-header'>Description</div>
                                <div class='description-content'>
                                    <div v-if='equityOverview.companyProfile.longDescription'>
                                        <ExpandableText :baseText='equityOverview.companyProfile.longDescription' :characterLimit='equityOverview.companyProfile.shortDescription.length - 3' />
                                    </div>
                                    <div v-else>
                                        {{equityOverview.companyProfile.shortDescription}}
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-6">
                                <div class='grid-header'>Sector</div>
                                <span class='sector-content'>{{equityOverview.securityListing.sectorName}}</span>
                            </div>
                            <div class="p-md-6">
                                <div class='grid-header'>Industry</div>
                                <span class='industry-content'>{{equityOverview.securityListing.industryName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left_col card" v-if='overviewStatsLoaded'>
                    <div class='company-overview-stats'>
                        <div class='p-grid'>
                            <div class='p-md-12'>
                                <div class='grid-header'>Overview
                                    
                                </div>
                                
                            </div>
                            <div class="p-md-12" v-if="selectedSymbol.endsWith(':AQL')" style="margin-bottom:15px;">
                                <small >(Note: Market Cap and 52 Week High/Low are in CAD dollars based on the CDR.  All other stats are based on the US company.)</small>
                               
                            </div>
                            <div class="p-md-6 left-col row-1">
                                <table>
                                    <tr>
                                        <td class='left-col'><p>Market Cap:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Market Cap')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Total Debt:</p></td>
                                        <td v-if="isFinancialInstitution()" class='right-col'><p>{{getOverviewStat('Total Debt')}}</p></td>
                                        <td v-else class='right-col'><p>{{getOverviewStat('Total Debt Calc')}}</p></td>
                                        
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Cash & Investments:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Cash & Investments')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Enterprise Value:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Enterprise Value')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Beta:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Beta')}}</p></td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <div class="p-md-6 right-col row-1">
                                <table>
                                    <tr>
                                        <td class='left-col'><p>Fiscal Year End:</p></td>
                                        <td class='right-col'><p>{{getFiscalYearEnd()}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Dividend Yield:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Dividend Yield')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Average Volume:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('Average Volume')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>52 Week High:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('52 Week High')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>52 Week Low:</p></td>
                                        <td class='right-col'><p>{{getOverviewStat('52 Week Low')}}</p></td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <div class="p-md-6 left-col">
                                <div class='grid-header'>Financials</div>
                                <table>
                                    <tr>
                                        <td class='left-col'><p>Revenue:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('Revenue')}}</p></td>
                                    </tr>
                                    <template v-if="isFinancialInstitution()">
                                        <tr>
                                            <td class='left-col'><p>Book Value / Share:</p></td>
                                            <td class='right-col'><p>{{getFinancialsStat('Book Value / Share')}}</p></td>
                                        </tr>
                                        <tr>
                                            <td class='left-col'><p>Tangible Book Value / Share:</p></td>
                                            <td class='right-col'><p>{{getFinancialsStat('Tangible Book Value / Share')}}</p></td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td class='left-col'><p>EBITDA:</p></td>
                                            <td class='right-col'><p>{{getFinancialsStat('EBITDA')}}</p></td>
                                        </tr>
                                        <tr>
                                            <td class='left-col'><p>EBIT:</p></td>
                                            <td class='right-col'><p>{{getFinancialsStat('EBIT')}}</p></td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td class='left-col'><p>Net Income:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('Net Income')}}</p></td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <div class="p-md-6 right-col">
                                <div class='grid-header'>Valuation</div>
                                <table>
                                    <tr>
                                        <td class='left-col'><p>Price / Earnings:</p></td>
                                        <td class='right-col'><p>{{getValuationStat('Price / Earnings')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Price / Book Value:</p></td>
                                        <td class='right-col'><p>{{getValuationStat('Price / Book Value')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Price / Sales:</p></td>
                                        <td class='right-col'><p>{{getValuationStat('Price / Sales')}}</p></td>
                                    </tr>
                                    <template v-if="isFinancialInstitution()">
                                        <tr>
                                            <td class='left-col'><p>Price / Tangible Book Value:</p></td>
                                            <td class='right-col'><p>{{getEVStat('Price / Tangible Book Value')}}</p></td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td class='left-col'><p>EV / EBITDA:</p></td>
                                            <td class='right-col'><p>{{getEVStat('EV / EBITDA')}}</p></td>
                                        </tr>
                                    </template>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        
        <div class="right-col p-col-12 p-md-5">
            <template v-if='!isMobile()'>
                <div class="card analyst-ratings-card">
                    <keep-alive>
                        <AnalystRating :selectedSymbol="selectedProxySymbol" key="arRatings" v-observe-visibility="visibilityChanged"/>
                    </keep-alive>
                </div>
                <PillMenuBar :navigationItems="newsMenuItems" headerType="sub" class='feed-header-menu'/>

                <div class="card corporate-events-container" style='height: 800px; width:100%;' v-if='selectedFeed == "corporateEvents"'>
                    <keep-alive>
                        <CorporateEvents :selectedSymbol="selectedSymbol" key="corporateEvents" v-observe-visibility="visibilityChanged"/>
                    </keep-alive>
                </div>
                <div class='social-feed' v-else-if='selectedFeed == "social"'>
                    <keep-alive>
                        <FeedActivityList :selectedEquity="selectedEquity" scrollStyle='width: 100%; height: 800px'/>
                    </keep-alive>
                </div>
                <div class='card news-feed-container' style='height: 800px; width:100%;'  v-else>
                    <keep-alive>
                        <MarketNews :selectedEquity="selectedEquity"/>
                    </keep-alive>
                </div>
            </template>
            <template v-else>
                <div class="card corporate-events-container" style='height: 1400px; width:100%' v-if='selectedFeedMobile == "corporateEvents"'>
                    <keep-alive>
                        <CorporateEvents :selectedSymbol="selectedSymbol" key="corporateEvents" v-observe-visibility="visibilityChanged"/>
                    </keep-alive>
                </div>
                <div v-else-if='selectedFeedMobile == "social"'>
                    <keep-alive>
                        <FeedActivityList :selectedEquity="selectedEquity" scrollStyle='width: 100%; height: 1400px'/>
                    </keep-alive>
                </div>
                <div class="card news-feed-container" style='height: 1400px; width:100%' v-else-if='selectedFeedMobile == "news"'>
                    <keep-alive>
                        <MarketNews :selectedEquity="selectedEquity" />
                    </keep-alive>
                </div>
                <div v-else-if='selectedFeedMobile == "overview"'>
                    <div class="card no-bottom-padding analyst-ratings-card">
                        <keep-alive>
                            <AnalystRating :selectedSymbol="selectedProxySymbol" key="arRatings" v-observe-visibility="visibilityChanged"/>
                        </keep-alive>
                    </div>
                    <div class="left-col card-sm card" v-if='profileLoaded'>
                        <div class='company-overview'>
                            <div class='p-grid'>
                                <div class="p-md-12">
                                    <div class='grid-header'>Description</div>
                                    <div class='description-content'>
                                        <div v-if='equityOverview.companyProfile.longDescription'>
                                            <ExpandableText :baseText='equityOverview.companyProfile.longDescription' :characterLimit='equityOverview.companyProfile.shortDescription.length - 3' />
                                        </div>
                                        <div v-else>
                                            {{equityOverview.companyProfile.shortDescription}}
                                        </div>
                                    </div>
                                </div>
                                <div class="sector-container">
                                    <div>
                                        <div class='grid-header'>Sector</div>
                                        <span class='sector-content'>{{equityOverview.securityListing.sectorName}}</span>
                                    </div>
                                    <div>
                                        <div class='grid-header'>Industry</div>
                                        <span class='industry-content'>{{equityOverview.securityListing.industryName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-col card-sm card" v-if='overviewStatsLoaded'>
                        <div class='company-overview-stats'>
                            <div class='grid-header'>Overview
                            </div>
                            <div v-if="selectedSymbol.endsWith(':AQL')" style="margin-bottom:15px;">
                                <small >(Note: Market Cap and 52 Week High/Low are in CAD dollars based on the CDR.  All other stats are based on the US company.)</small>
                               
                            </div>
                            <table>
                                <tr>
                                    <td class='left-col'><p>Market Cap:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Market Cap')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Total Debt:</p></td>
                                    <td v-if="isFinancialInstitution()" class='right-col'><p>{{getOverviewStat('Total Debt')}}</p></td>
                                    <td v-else class='right-col'><p>{{getOverviewStat('Total Debt Calc')}}</p></td>
                                    
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Cash & Investments:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Cash & Investments')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Enterprise Value:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Enterprise Value')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Beta:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Beta')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Fiscal Year End:</p></td>
                                    <td class='right-col'><p>{{getFiscalYearEnd()}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Dividend Yield:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Dividend Yield')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Average Volume:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('Average Volume')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>52 Week High:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('52 Week High')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>52 Week Low:</p></td>
                                    <td class='right-col'><p>{{getOverviewStat('52 Week Low')}}</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="left-col card-sm card" v-if='overviewStatsLoaded'>
                        <div class='company-overview-stats'>
                            <div class='grid-header'>Financials</div>
                            <table>
                                <tr>
                                    <td class='left-col'><p>Revenue:</p></td>
                                    <td class='right-col'><p>{{getFinancialsStat('Revenue')}}</p></td>
                                </tr>
                                <template v-if="isFinancialInstitution()">
                                    <tr>
                                        <td class='left-col'><p>Book Value / Share:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('Book Value / Share')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>Tangible Book Value / Share:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('Tangible Book Value / Share')}}</p></td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class='left-col'><p>EBITDA:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('EBITDA')}}</p></td>
                                    </tr>
                                    <tr>
                                        <td class='left-col'><p>EBIT:</p></td>
                                        <td class='right-col'><p>{{getFinancialsStat('EBIT')}}</p></td>
                                    </tr>
                                </template>
                                <tr>
                                    <td class='left-col'><p>Net Income:</p></td>
                                    <td class='right-col'><p>{{getFinancialsStat('Net Income')}}</p></td>
                                </tr>
                                
                            </table>
                        </div>
                    </div>
                    <div class="company-overview-stats left-col card-sm card" v-if='overviewStatsLoaded'>
                        <div class='company-overview-stats'>
                            <div class='grid-header'>Valuation</div>
                            <table>
                                <tr>
                                    <td class='left-col'><p>Price / Earnings:</p></td>
                                    <td class='right-col'><p>{{getValuationStat('Price / Earnings')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Price / Book Value:</p></td>
                                    <td class='right-col'><p>{{getValuationStat('Price / Book Value')}}</p></td>
                                </tr>
                                <tr>
                                    <td class='left-col'><p>Price / Sales:</p></td>
                                    <td class='right-col'><p>{{getValuationStat('Price / Sales')}}</p></td>
                                </tr>
                                <template v-if="isFinancialInstitution()">
                                    <tr>
                                        <td class='left-col'><p>Price / Tangible Book Value:</p></td>
                                        <td class='right-col'><p>{{getEVStat('Price / Tangible Book Value')}}</p></td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class='left-col'><p>EV / EBITDA:</p></td>
                                        <td class='right-col'><p>{{getEVStat('EV / EBITDA')}}</p></td>
                                    </tr>
                                </template>
                                
                            </table>
                        </div>
                    </div>
                    <div class="card-sm card">
                        <keep-alive>
                            <Earnings :selectedEquity="selectedEquity" key="earnings" v-observe-visibility="visibilityChanged"/>
                        </keep-alive>
                    </div>
                </div>
            </template>
        </div>
        <div class="p-col-12 p-md-12" v-if="!isMobile()">
            <div class="card-sm card">
                <keep-alive>
                    <Earnings :selectedEquity="selectedEquity" key="earnings" v-observe-visibility="visibilityChanged"/>
                </keep-alive>
            </div>
        </div>
    </div>
    <AttributionFooter authors='quoteMedia' v-if='profileLoaded'/>
</div>

</template>

<script>

import PillMenuBar from '../menu/PillMenuBar';
import ExpandableText from '../common/ExpandableText';
import AnalystRating from '../QuoteMedia/AnalystRating';
import CorporateEvents from '../QuoteMedia/CorporateEvents';
import SecurityOverviewChart from '../QuoteMedia/SecurityOverviewChart';
import Earnings from '../QuoteMedia/Earnings';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';
import CarouselMenuBar from '../menu/CarouselMenuBar.vue';
import MarketNews from '../QuoteMedia/MarketNews.vue';

import { mapState } from 'vuex';
import ResearchService from '../../service/ResearchService';

import SecurityFormatter from '../../common/SecurityFormatter';
import BrowserUtils from '../../utilities/BrowserUtils';
import FormattingUtils from '../../utilities/FormattingUtils';
import format from 'date-fns/format';
import setYear from 'date-fns/setYear'
import getYear from 'date-fns/getYear'
import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore'
import FeedService from '../../service/FeedService';
import FeedActivityList from '../feed/FeedActivityList.vue';

import {STATEMENT_TEMPLATE_IDS} from './common.js';

export default {
    name: "Overview",
    data() {
        return {
            equityOverview: null,
            overviewStats: {},
            justMounted: false,
            active: false,
            currentSymbol: null,
            profileLoaded: false,
            overviewStatsLoaded: false,
            newsMenuItems: [
                {label: 'Posts', action: this.socialFeedClicked, selected: false},
                {label: 'Events', action: this.corporateEventsFeedClicked, selected: true},
                {label: 'News', action: this.newsFeedClicked, selected: false}
            ],
            selectedFeed: 'corporateEvents',
            menu: [
                {
                    label: 'Overview',
                    action: this.overviewClickedMobile,
                    selected: true,
                },
                {
                    label: 'Events',
                    action: this.corporateEventsFeedClickedMobile,
                },
                {
                    label: 'Posts',
                    action: this.socialFeedClickedMobile,
                },
                {
                    label: 'News',
                    action: this.newsFeedClickedMobile
                }
            ],
            selectedFeedMobile: "overview",
        }
    },
    components : {
        PillMenuBar,
        CarouselMenuBar,
        ExpandableText,
        AnalystRating,
        CorporateEvents,
        SecurityOverviewChart,
        Earnings,
        FeedActivityList,
        LoadingSpinner,
        AttributionFooter,
        MarketNews
    },
    computed: {
        ...mapState(['selectedEquity']),
        selectedSymbolForTv() {
            let exchangeSymbol = this.selectedEquity.exchangeSymbol;
            let symbol = this.selectedEquity.symbol;
            if( exchangeSymbol == 'XNAS') {
                return "NASDAQ:" + symbol
            }
            else if( exchangeSymbol == 'XNYS') {
                return "NYSE:" + symbol
            }
            else if( exchangeSymbol == 'OOTC') {
                return "OTC:" + symbol
            }
            else if( exchangeSymbol == 'XTSE') {
                return "TSX:" + symbol
            }
            else if( exchangeSymbol == 'XTSX') {
                return "TSXV:" + symbol
            }
            else if( exchangeSymbol == 'XCNQ') {
                return "CSE:" + symbol
            }
            else {
                return symbol;
            }
            
        },
        
        selectedSymbol() {
            
            return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
        },

        selectedProxySymbol() {
            if( this.selectedEquity.proxySymbol ) {
                return this.selectedEquity.proxySymbol.replace('/', '.');
            }
            else {
                return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
            }
        }
    },

    created() {
        this.formatter = new FormattingUtils();
    },
    watch: {
        selectedEquity() {
            
            if ( !this.justMounted  ) {
                this.profileLoaded = false;
                this.overviewStatsLoaded = false;
                this.fetchEquityOverview();

                if( this.currentSymbol != this.selectedSymbol && this.$route.path =='/equity') {
                    this.resetSelection();
                    this.currentSymbol = this.selectedSymbol;    
                    window.earningsQMod.loadTools();
                    window.analystQMod.loadTools(); 
                    window.corporateEventsQMod.loadTools();
                    window.newsQMod.loadTools();
                    window.securityOverviewChartQMod.loadTools();
                }
                
            }
        
        },
         $route(newRoute, oldRoute) { //eslint-disable-line
            if( newRoute.fullPath =='/equity') {
               
               if( !this.justMounted && this.active){
                    if( this.currentSymbol != this.selectedSymbol) {
                        this.resetSelection();
                        this.profileLoaded = false;
                        this.overviewStatsLoaded = false;
                        this.currentSymbol = this.selectedSymbol;
                        window.analystQMod.loadTools(); 
                        window.corporateEventsQMod.loadTools();
                        window.earningsQMod.loadTools();
                        window.newsQMod.loadTools();
                        window.securityOverviewChartQMod.loadTools();
                    }
                    // else {
                    //     window.dispatchEvent(new Event('resize'))
                    // }
                    if( this.$refs.chart){
                        this.$refs.chart.startPollingPriceChange();
                    }
                }
            }
            else{
                if( this.$refs.chart){
                    this.$refs.chart.stopPollingPriceChange();
                }
            }
        },
    },
    mounted() {
        
        this.profileLoaded = false;
        this.overviewStatsLoaded = false;
        this.fetchEquityOverview();
        
        this.justMounted = true;
        this.currentSymbol = this.selectedSymbol;
        //need to load news once this way as it was previously loaded from dashboard
        // window.newsQMod.loadTools(); 
        
    },
    activated() {
        console.log("equity overview activated")
        this.active = true;
        
        if( !this.justMounted){
            if( this.currentSymbol != this.selectedSymbol) {
                this.resetSelection();
                this.profileLoaded = false;
                this.overviewStatsLoaded = false;
                this.currentSymbol = this.selectedSymbol;
                window.earningsQMod.loadTools();
                window.analystQMod.loadTools(); 
                window.corporateEventsQMod.loadTools();
                window.newsQMod.loadTools(); 
                window.securityOverviewChartQMod.loadTools();

                FeedService.getEquityActivities();
            }
        }
        this.justMounted = false;
    },
    deactivated() {
        console.log("equity overview deactivated")
        this.justMounted = false;
        this.active = false;
        //this.profileLoaded = false;
        this.overviewStatsLoaded = false;
    },
    
    
    methods: {

        getQMExchangeSymbolSuffix() {
            return SecurityFormatter.getQMExchangeSymbolSuffix();
        },

        resetSelection() {
            this.selectedFeed =  'corporateEvents';

            this.newsMenuItems.forEach((item) => {
                item.selected = false;
            })

            this.newsMenuItems[1].selected = true;
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible) {
                 window.dispatchEvent(new Event('resize'));
            }
        },

        isFinancialInstitution() {
            return (this.equityOverview.company.statementTemplateId == STATEMENT_TEMPLATE_IDS['BANK'] 
                        ||
                    this.equityOverview.company.statementTemplateId == STATEMENT_TEMPLATE_IDS['INSURANCE'] );
        },
        getFiscalYearEnd() {
            if( this.equityOverview.fiscalYearEnd) {
                return format(this.equityOverview.fiscalYearEnd, 'MM/dd/yyyy');
            }
            else if( this.equityOverview.companyProfile.fiscalPeriodEndDate ){
                let now = new Date();
                let fiscalYearEnd = new Date(this.equityOverview.companyProfile.fiscalPeriodEndDate);
                fiscalYearEnd = setYear(fiscalYearEnd, getYear(now));
                if( isBefore(fiscalYearEnd, now) ) {
                    fiscalYearEnd = add(fiscalYearEnd,{years:1});
                }
                
                return format(fiscalYearEnd, 'MM/dd/yyyy');
            }
            else {
                return "-";
            }
        },
        

        getOverviewStat(name){
            let stat = this.overviewStats.overview.filter(obj => {
                return ( obj.name === name );
                    
                });
            
            return this.getStatValue(stat[0]);
                
        },

        getStatValue(stat) {
            if( stat) {
                return stat.value;
            }
            else {
                return '-';
            }
        },


        getFinancialsStat(name){
            let stat = this.overviewStats.financials.filter(obj => {
                return obj.name === name;
            });
            return this.getStatValue(stat[0]);
        },


        getValuationStat(name){
            let stat = this.overviewStats.valuation.filter(obj => {
                return obj.name === name;
            });
            return this.getStatValue(stat[0]);
        },

        getEVStat(name){
            let stat = this.overviewStats.ev.filter(obj => {
                return obj.name === name;
            });
            return this.getStatValue(stat[0]);
        },

        async fetchEquityOverview() {
            await ResearchService.getEquityOverview(this.selectedEquity.securityId).then(response => {
                
                this.equityOverview = response.data;
                this.$store.commit("SET_EQUITY_OVERVIEW", this.equityOverview);
                
                this.getCompanyOverviewStats();
                this.profileLoaded = true;
            })
            .catch(error => {
                console.debug('There was an error:', error.response)
            });
        },
        

        /* This gets called on each element in the array that calls 'reduce' 
            which applies the actual formatting util onto the current element's value*/
        formatOverviewStat(total, stat) {
            if( stat.value ) {
                total.push({'name': stat.name, 'value': this.formatter.formatSecurityStat(stat)})
            }
            else {
                total.push({'name': stat.name, 'value': '-'});
            }

            return total;
        },
        getCompanyOverviewStats() {
            let rawOverviewStats = this.equityOverview.stats;

            
            /* In each respective array: Take in elements, format them accordingly depending on valueType 
                and basically push all formatted elements into an array - Assigning them to the original array member*/
            this.overviewStats.overview = rawOverviewStats.overview.reduce(this.formatOverviewStat, []);
            this.overviewStats.financials = rawOverviewStats.financials.reduce(this.formatOverviewStat, []);
            this.overviewStats.valuation = rawOverviewStats.valuation.reduce(this.formatOverviewStat, []);
            this.overviewStats.ev = rawOverviewStats.ev.reduce(this.formatOverviewStat, []);

            this.overviewStatsLoaded = true;
        },

        corporateEventsFeedClicked() {
            this.selectedFeed = 'corporateEvents';
        },
        socialFeedClicked() {
            this.selectedFeed = 'social';
        },
        newsFeedClicked() {
            this.selectedFeed = 'news';
        },
        overviewClickedMobile() {
            this.selectedFeedMobile = 'overview';
        },
        corporateEventsFeedClickedMobile() {
            this.selectedFeedMobile = 'corporateEvents';
        },
        socialFeedClickedMobile() {
            this.selectedFeedMobile = 'social';
        },
        newsFeedClickedMobile() {
            this.selectedFeedMobile = 'news';
        }
    }

}
</script>

<style scoped lang="scss">
.card.no-bottom-padding {
    padding-bottom: 0px;
}

.sector-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.card {
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
}
.card:last-child {
    margin-bottom: 0px;
}

.right-col .news-feed-container,
.right-col .corporate-events-container {
    padding: 0px;
}

.p-grid [class*=-col] .card:first-child {
    margin-top: 6px;
}
.p-grid > [class*=-col]:last-child {
    margin-top: 16px;
    padding: 0;
}

.grid-header {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 8px;
}

[class*=company-overview] {
    padding: 16px 16px 0px;
}
[class*=company-overview] .p-grid [class*=p-],
[class*=company-overview] .p-grid [class*=p-] .left-col {
    padding: 0px;
}

.company-overview .description-content {
    font-family: "Trebuchet MS", "Verdana";
    width: 100%; 
    line-height: 1.4;
    color: black;
    margin-bottom: 16px;
}
.company-overview .sector-content,
.company-overview .industry-content {
    font-family: 'Trebuchet MS', 'Verdana';
    color: black;
}

.company-overview-stats table p {
    margin: 0px;
    line-height: 1.4;
}
.company-overview-stats .left-col table {
    padding-right: 16px;
}
.company-overview-stats .p-grid .row-1 {
    padding-bottom: 16px;
}
.company-overview-stats table {
    width: 100%;
    font-family: "Trebuchet MS", "Verdana";
}
.company-overview-stats table .left-col {
    text-align: left;
}
.company-overview-stats table .right-col {
    color: black;
    text-align: right;
}

/* Disabling Feed button from sub menubar in the meantime until feed component is integrated */
/* ::v-deep(.feed-header-menu .navigation .p-button:first-child) {
    opacity: 0.5;
    pointer-events: none;
} */

/* ::v-deep(.corporate-events-scrollpanel) {
    width: 100%;
    height: 100%;
}
::v-deep(.corporate-events-scrollpanel .p-scrollpanel-content) {
    padding: 0;
}
::v-deep(.corporate-events-scrollpanel .p-scrollpanel-wrapper) {
    border-right: 9px solid #F2F2F2;
}
::v-deep(.corporate-events-scrollpanel .p-scrollpanel-bar) {
    background-color: #BFBFBF;
    opacity: 1;
    transition: background-color .2s;
} */

@media (max-width: 760px) {

    .card-sm {
        padding: 16px !important;
        border-radius: 0;
    }
    .card {
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
    }
    .company-overview {
        border-radius: 0;
        padding: 0;
    }
    .company-overview-stats {
        padding: 0px;
    }
    .p-grid [class*=-col] .card:first-child {
        margin-top: 0px;
    }
    table {
        width: 100%;
    }
    tr{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .p-grid {
     margin: 0;
    }
    .tab-content {
        padding-top: 0px;
    }

    ::v-deep(.security-overview-chart-card .advanced-txt),
    ::v-deep(.card .more-options) {
        display: none;
    }
    ::v-deep(.left-col){
        position: relative;
        z-index: 0;
    }
    ::v-deep(.social-feed .card .more-options) {
        display: inline-flex;
    }

    ::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting:last-child:after),
    ::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting_asc:last-child:after), 
    ::v-deep(.qmod-corpevents table.dataTable thead tr th.sorting_desc:last-child:after) {
        right: 9%;
    }
    // .p-grid [class*=-col] .card:first-child {
    //  margin-top: 0px;
    // }
}
</style>
